.home-intro-box-main {
    background-color: #ffffff;
    padding: 40px 15px 40px 60px;
    margin-bottom: 30px;
    border-radius: 5px;
    // border: 1px solid #ededed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2%;
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);

    .home-intro-box {
        flex: 0 0 48%;
        flex-direction: column;
        max-width: 48%;

        h1 {
            color: #252525;
            font-size: 22px;
            // font-weight: 800!important;
            line-height: 2rem;
            margin-bottom: 10px;
            padding: 10px 0 10px;
        }

        .list-points {
            list-style: none;
            margin: 0 0 15px 0;
            padding: 0;
            width: 100%;
            display: inline-block;

            li {
                position: relative;
                color: #0f0b1f;
                padding: 0 0 20px 0;
                line-height: 20px;
                font-size: 16px;
                font-weight: 400;
                display: flex;
                align-items: center;

                .list-icon {
                    margin-right: 15px;
                    font-size: 19px;
                    font-weight: 700;
                    color: #87cf82;
                }
            }

            // li:before {
            //     position: absolute;
            //     left: 0px;
            //     top: 1px;
            //     font-size: 19px;
            //     color: #87cf82;
            //     content: "\f00c";
            //     font-family: fontAwesome !important;
            // }
        }

        .intro-img {
            max-width: 400px;
            margin: auto;
        }
    }
}

.title-area {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;

    h1 {
        color: #252525;
        font-size: 25px;
        letter-spacing: 1px;
        font-weight: 800 !important;
        margin-bottom: 0;
        padding: 0;
    }

    .logo-title-area {
        width: 120px;
    }
}

.content-listing-wrapper {
    .live-shopping-grid {
        display: grid;
        grid-row-gap: 30px;
        grid-column-gap: 30px;
        grid-template-columns: repeat(4, 1fr);

        .video-thumbnail {
            position: relative;
            border-radius: 4px;
            height: 180px;
            padding: 15px;
            position: relative;
            background-color: lightgrey;
            display: grid;
            align-items: center;
            justify-content: space-between;
            grid-row-gap: 0px;
            grid-column-gap: 10px;
            grid-template-columns: repeat(2, 1fr);
            background: linear-gradient(113.19deg, #ebe0d2, #dbdee5);

            .left-content {
                align-self: flex-start;

                .fa-circle-play {
                    font-size: 32px;
                    margin-bottom: 10px;
                }

                h3 {
                    color: #252525;
                    font-size: 15px;
                    letter-spacing: 1px;
                    font-weight: 700 !important;
                    line-height: 20px;
                    margin-bottom: 0;
                    padding: 0 12px 0 0;
                }
            }

            .right-img {

                // height: 150px;
                img {
                    width: 100%;
                    height: 150px;
                }
            }
        }

        &.three-grid {
            grid-template-columns: repeat(3, 1fr);

            .video-thumbnail {
                height: 210px;
            }
        }
    }
}

.upcoming-events-wrapper {

    .upcoming-events-grid {
        display: grid;
        grid-gap: 2rem;
        // grid-row-gap: 20px;
        // grid-column-gap: 30px;
        // grid-template-columns: repeat(1, 1fr);

        .upcoming-events-box {
            background-color: #ffffff;
            padding: 0px;
            border-radius: 5px;
            -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
            box-shadow: 0 0 2px rgb(0 0 0 / 26%);
            display: grid;
            grid-template-columns: .85fr 3fr 1.22fr;

            .event-banner {
                position: relative;
                // background-color: #c1c1c1;
                border-radius: 5px 0 0 5px;
                // background-color: #010b40;
                background: #00008a;
                background: -webkit-gradient(left top, left bottom, color-stop(0, #00008a), color-stop(100%, #000c40));
                background: linear-gradient(180deg, #00008a 0, #000c40);

                background: #00008a;
                background: -webkit-gradient(left top, left bottom, color-stop(0, #00008a), color-stop(100%, #000c40));
                background: linear-gradient(180deg, #00008a 0, #000c40);

                
                // background-image: url(../images/live-shopping-bg.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                // background: linear-gradient(180deg, #222b5d, #010b40);
                width: 100%;
                // min-height: 150px;
                height: 150px;
                color: #ffffff;

                .event-box {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    min-height: 150px;

                    .event-banner-img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 150px;
                        line-height: 146px;
                        overflow: hidden;
                        text-align: center;
                        z-index: 0;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }

                    .schedule-badge {
                        padding: 0.5rem 1rem 0.5em 1rem;
                        margin: 8px 0 0 8px;
                        text-transform: uppercase;
                        text-align: center;
                        font-weight: 600 !important;
                        font-family: Nunito, sans-serif;
                        line-height: 1;
                        font-size: 66%;
                        // font-size: 11px;
                        color: #00aeb3;
                        background-color: #cceff0;
                        border-radius: 100px !important;
                        display: inline-block;
                        filter: drop-shadow(0 3px 3px rgba(0, 0, 0, 0.1));
                        position: relative;
                        z-index: 1;

                        &.live {
                            -webkit-animatiown: blinkingText 1.2s infinite;
                            animation: blinkingText 1.2s infinite;

                            @keyframes blinkingText {
                                0% {
                                    color: #f85c5b;
                                }

                                49% {
                                    color: #f85c5b;
                                }

                                60% {
                                    color: transparent;
                                }

                                99% {
                                    color: transparent;
                                }

                                100% {
                                    color: #f85c5b;
                                }
                            }
                        }

                        &.expired {
                            color: #f85c5b;
                            background-color: #ffecec;
                        }

                        &.past {
                            color: #f85c5b;
                            // background-color: #ffecec;
                        }

                        &.Recorded {
                            color: #333;
                            background: #cccccc;
                        }

                        &.countdown-timer {
                            // color: red;
                            background: #cceff0;
                            margin-right: 8px;
                            padding: 0.3rem 1rem 0.3em 1rem;
                            filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.3));

                            .ant-statistic {
                                line-height: initial;

                                .ant-statistic-content {
                                    color: #e52b2b;
                                    font-size: 90%;
                                    font-weight: 500 !important;
                                    font-family: Nunito, sans-serif;
                                    // text-shadow: 1px 1px #000000;
                                }
                            }
                        }
                        &.banner-category {
                            color: #e52b2b;
                        }
                    }

                    .ribbon-small {
                        min-height: 10px;
                        // background: -webkit-gradient(linear, left top, left bottom, from(#6270bd), to(#6270bd));
                        // background: linear-gradient(180deg, #6270bd, #6270bd);
                        background-color: #586197;
                        padding: 3px 10px 0;
                        bottom: 0;
                        right: 0;
                        color: #fff;
                        -webkit-box-shadow: 0 -1px 1px rgb(0 0 0 / 25%);
                        box-shadow: 0 -1px 1pxrgba(0, 0, 0, .25);
                        border-radius: 4px 0 0 0;
                        position: relative;
                        z-index: 2;

                        .fa-flask {
                            margin-right: 7px;
                        }

                        &.fb-style {
                            border-radius: 0 4px 0 0;
                        }
                    }
                }

            }

            .event-card-content {
                padding: 1.5rem;

                .event-date-main {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }

                .event-date {
                    display: flex;
                    align-items: center;
                    // padding-bottom: 5px;

                    .event-icon {
                        margin-right: 8px;
                        color: #7d84a9;
                    }

                    .event-txt {
                        margin-right: 10px;
                        color: grey;
                        font-size: 13px;
                        // font-family: Nunito Sans, sans-serif !important;

                        .ant-statistic-content {
                            font-size: 14px;
                            color: #eb335b;
                        }

                        &.recorded-gap {
                            margin-right: 2px;
                        }
                    }


                    &.bottom-style {
                        padding-bottom: 0;

                        .event-icon {
                            color: #1a2458;
                        }

                        .event-txt {
                            color: #162b4c;

                        }
                    }
                }

                .event-date-recorded {
                    font-weight: 700;
                    font-family: Nunito Sans, sans-serif !important;
                    min-width: 80px;
                    font-size: 13px;
                }


                .event-hd {
                    color: #252525;
                    font-size: 18px;
                    line-height: 1.8rem;
                    padding: 10px 0 10px;
                    // height: 66px;
                    // overflow: hidden;
                }

                @media only screen and (max-width: 1280px) {
                    .event-hd {
                        font-size: 16px;
                    }
                }

                @media only screen and (max-width: 1465px) {
                    .event-txt {
                        font-size: 12px !important;

                    }

                    .event-date-recorded {
                        font-size: 12px;
                        min-width: 65px;
                    }
                }
            }

            .event-card-footer {
                padding: 1.5rem;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                min-height: 66px;
                gap: 5px;

                .default-btn {
                    font-size: 13px;
                    //    min-width: 171px;
                    width: fill-available;
                    width: -webkit-fill-available;
                    width: -moz-available;
                    margin: 0;

                    &.share-btn {
                        min-width: initial;
                        border-radius: 3px;
                        height: 45px;
                        width: 45px;

                        &:hover {}

                        &:focus {}
                    }
                }
            }
        }
    }

    &.grid-block {
        .upcoming-events-grid {
            // grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
            grid-template-columns: repeat(auto-fill, minmax(305px, 1fr));

        }

        .upcoming-events-box {
            // display: block;
            display: flex;
            flex-direction: column;
            grid-template-columns: initial;

            .event-banner {
                // border-radius: 5px 5px 0 5px;
                border-radius: 5px 5px 0 0px;
            }

            .event-users-listing {
                display: flex;
                justify-content: space-between;
                align-items: start;
                flex: 1;
            }

            .event-card-content .event-date-main {
                flex-direction: column;
                align-items: start;
                gap: 5px;

                &.top-date {
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-bottom: 2px;
                }
            }

            .event-hd {
                height: 65px;
                display: -webkit-box;
                // max-width: 400px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .event-card-footer {
                padding: 0 1.5rem 1.5rem 1.5rem;
                justify-content: space-between;
                gap: 5px;

                .default-btn {
                    margin: 0;
                }
            }
        }

        @media only screen and (max-width: 1626px) {
            .upcoming-events-grid {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            }
        }

        @media only screen and (max-width: 1406px) {

            .upcoming-events-grid {
                // grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
                grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));

            }

            .event-txt {
                font-size: 12px;
            }
        }

        @media only screen and (max-width: 1250px) {
            .upcoming-events-grid {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            }
        }

        @media only screen and (max-width: 1200px) {
            .upcoming-events-grid {
                grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));

            }
        }

        @media only screen and (max-width: 1000px) {
            .upcoming-events-grid {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            }
        }

        @media only screen and (max-width: 675px) {
            .upcoming-events-grid {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            }
        }

        @media only screen and (max-width: 576px) {
            .upcoming-events-grid {
                grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

            }
        }
    }
    &.list-block {
        .fee-box-main {
            gap: 15px;
            margin: 10px 0 10px 0;
            flex-direction: row;
            justify-content: start;
        }
    }
}

.control-room-drowpdown {
    min-width: 13rem !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;

    .ant-dropdown-menu {
        border-radius: .4375rem;
        padding: .5rem 0;

        .ant-dropdown-menu-item {
            padding: .5rem 1rem;
            font-size: 14px;
        }

        .ant-dropdown-menu-item-icon {
            margin-right: 20px;
            font-size: 16px;
            min-width: 20px;
        }
    }
}

.ant-dropdown-button {
    button:first-child {
        display: none !important;
    }

    .ant-dropdown-trigger {
        border-radius: 4px !important;
        min-height: 45px !important;
        padding: 12px 12px 12px 12px !important;
        min-width: 45px !important;
        border: 1px solid grey !important;
        // margin-left: 10px !important;
        margin-left: 0px !important;

        &:hover {
            color: #787878;
            background-color: hsla(0, 0%, 50.2%, .2);
        }

        &:focus {
            color: #787878;
            background-color: hsla(0, 0%, 50.2%, .2);
        }
    }
}






.ant-tooltip {
    .ant-tooltip-inner {
        border-radius: 5px;
        background-color: #292929;
        padding: 7px 15px;
    }

}


.social-modal-banner-main {
    width: 100%;
    height: 150px;
    line-height: 146px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000080;
    border: 1px solid #efefef;
    border-radius: 5px 5px 5px 5px;

    .social-modal-banner {
        max-width: 100%;
        max-height: 100%;
    }
}

.event-link {
    color: #007bff;
}

@media only screen and (max-width: 1600px) {
    .content-listing-wrapper {
        .live-shopping-grid {
            .video-thumbnail {
                .left-content {
                    h3 {
                        font-size: 14px;
                        letter-spacing: 1px;
                        line-height: 18px;
                        margin-bottom: 0;
                        padding: 0 12px 0 0;
                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 1440px) {
    .upcoming-events-wrapper {
        &.grid-block {
            .upcoming-events-box {
                .event-card-footer {
                    .default-btn {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .content-listing-wrapper {
        .title-area {
            h1 {
                font-size: 22px;
                letter-spacing: 1px;
            }
        }
    }

    .content-listing-wrapper {
        .live-shopping-grid {
            .video-thumbnail {
                .left-content {
                    h3 {
                        font-size: 13px;
                        letter-spacing: 0.5px;
                        line-height: 18px;
                        padding: 0 0 0 0;
                    }
                }
            }
        }
    }

}


.home-single-grid-show {
    .upcoming-events-wrapper {
        .upcoming-events-grid {
            .upcoming-events-box+.upcoming-events-box {
                // display: none;
            }
        }
    }
}

@media only screen and (max-width: 1410px) {
    .upcoming-events-wrapper {
        &.grid-block {
            .upcoming-events-box {
                .event-card-footer {
                    padding: 0 0.5rem 1rem 0.5rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 1280px) {
    .title-area h1 {
        font-size: 22px;
        letter-spacing: 1px;
    }

    .content-listing-wrapper {
        .live-shopping-grid {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media only screen and (max-width:1200px) {
    .content-listing-wrapper {
        .live-shopping-grid {

            .video-thumbnail {
                height: 200px;

            }
        }
    }
}

@media only screen and (max-width: 991px) {
    .title-area h1 {
        font-size: 18px;
        letter-spacing: 0px;
    }

    .content-listing-wrapper {
        .live-shopping-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media only screen and (max-width: 800px) {
    .content-listing-wrapper {
        .live-shopping-grid {
            grid-template-columns: repeat(1, 1fr);

            .video-thumbnail {
                height: initial;
            }
        }
    }
}

@media only screen and (max-width: 800px) {
    .upcoming-events-wrapper {
        .upcoming-events-grid {
            .upcoming-events-box {
                .event-card-content {
                    .event-hd {
                        font-size: 18px;
                    }
                }

                .event-card-footer {
                    justify-content: center;
                }
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .default-btn {
        font-size: 13px;
    }

    .home-intro-box-main {
        padding: 10px 15px 20px 20px;

        .home-intro-box {
            flex: 0 0 100%;
            flex-direction: row;
            max-width: 100%;

            h1 {
                font-size: 16px;
                line-height: initial;
                margin: 0;
            }

            .list-points {
                li {
                    padding: 0 0 5px 20px;
                    line-height: 20px;
                    font-size: 14px;

                    .list-icon {
                        font-size: 15px;
                        margin-right: 10px;
                    }
                }
            }

        }
    }
}

@media only screen and (max-width: 576px) {
    .content-listing-wrapper {
        .live-shopping-grid {
            grid-row-gap: 15px;
            grid-column-gap: 15px;
        }
    }

    .content-listing-wrapper {
        .live-shopping-grid {
            .video-thumbnail {
                .right-img {
                    img {
                        width: 100%;
                        height: 100px;
                    }
                }
            }
        }
    }

    .upcoming-events-wrapper {
        .upcoming-events-grid {
            .upcoming-events-box {
                .event-card-content {
                    .event-hd {
                        font-size: 16px;
                        line-height: initial;
                    }
                }
            }
        }
    }
}

.media-processing .process-hd {
    font-size: 14px;
    font-weight: 700!important;
}