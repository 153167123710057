.c-top-menu {
  border-bottom: 1px solid #dce4ec;
  border-top: 1px solid #dce4ec;
  width: 100%;
  background-color: white;

  .middle-width {
    -webkit-padding-end: 24px;
    -webkit-padding-start: 24px;
    box-sizing: initial;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    padding-inline-end: 24px;
    padding-inline-start: 24px;

    .c-top-menu-inner {
      display: flex;
      position: relative;
      width: 100%;
    }
  }

}


.public-header {
  padding: 0;
  // border-bottom: 1px solid #cccccc;
  width: 100%;
  background: white;
  font-family: Nunito Sans, sans-serif !important;
  .middle-width1 {
    -webkit-padding-end: 24px;
    -webkit-padding-start: 24px;
    box-sizing: initial;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 100%;
    padding-inline-end: 24px;
    padding-inline-start: 24px;
  }
  .public-header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 25px;
    padding: 21px 0;

    .header-left {
      display: flex;
      align-items: center;
      gap: 25px;
    }
  }
}

.public-logo {
  //  width: 110px;
  margin: 0;

  img {
    width: 110px;
  }
}

.default-btn {
  &.rounded {
    border-radius: 100px !important;
    padding: 8px 10px 0px 10px !important;
  }
}

.public-menu {
  width: 100%;
  // display: inline-block;
  margin: 0;

  >ul {

    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    >li {

      align-items: center;
      display: flex;
      padding: 16px;
      position: relative;

      &:last-child {
        padding-right: 0px;
      }

      >a {
        padding: 0px;
        color: #7b8994;
        font-size: 1rem;
        font-family: 'Nunito Sans', sans-serif !important;
        font-weight: 600;
        // display: flex;
        // align-items: center;
        text-decoration: none;

        &.active {
          color: #000000;
          font-weight: 700;
        }

        .menu-icon {
          margin-right: 5px;

          &.home-icon {
            font-size: 12px;
            position: relative;
            top: -1px;
          }
        }
      }

      &.active>a {
        color: #000000;
        font-weight: 700;
      }

      >a {

        &:hover,
        &.active,
        &:focus {
          color: #000000;
        }
      }


      .top-submenu {



        display: none;
        left: 0;
        min-width: 220px;
        padding-top: 5px;
        position: absolute;
        top: 50px;
        z-index: 9;

        ul {
          background-color: #fff;
          flex-direction: column;
          box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
          justify-content: flex-start;
          border-radius: 0 0 6px 6px;
          padding: 10px;

          li {
            padding: 0;
            width: 100%;
            border-bottom: solid 1px #dce4ec;
            list-style-type: none;

            a {
              width: 100%;
              padding: 12px 10px;
              color: #7b8994;
              display: block;

              &:hover {
                color: #000;
              }
            }

            &:last-child {
              border: none;
            }
          }
        }
      }

      &:hover {
        .top-submenu {
          display: block;
        }
      }

      &:first-child {
        padding-left: 0;
      }


    }
  }
}

.public-footer {
  background: #222328;

  .footer-content {
    // padding: 10px 0;
    padding: 15px 0;

    .copyright {
      color: white;
      font-size: 14px;
      font-family: 'Nunito Sans', sans-serif !important;
    }
  }

  .footer-inline-nav {
    list-style-type: none;
    margin: 10px 0 10px 0;
    padding: 0;

    li {
      float: left;
      padding: 0 12px;
      position: relative;

      &:last-child {
        padding-right: 0px;
      }

      &:before {
        position: absolute;
        content: '';
        width: 1px;
        height: 11px;
        background: white;
        left: 0px;
        top: 30%;
      }

      &:first-child:before {
        display: none;
      }

      a {
        font-size: 14px;
        font-weight: 400;
        color: white;
        font-family: 'Nunito Sans', sans-serif !important;

        &:hover {
          color: #c2c2c2;
        }
      }
    }
  }
}


.mobile_footer_ifti {
  display: none;
}

.brd_footer_main {
  width: 100%;
  background-color: #252525;
  padding-top: 64px;
  // min-height: 400px;

  .footer-new-design {
    display: flex;
    width: 100%;
    flex-direction: column;

    .brd-footer-top {
      display: flex;
      // justify-content: space-between;
      color: #fff;
      gap: 200px;

      .brd-footer-box {
        h4 {
          color: #fff;
          font-size: 12px;
          text-transform: uppercase;
          opacity: 0.7;
          margin-bottom: 10px;
        }
        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;
          li {
            width: 100%;
            a {
              color: #fff;
              font-size: 14px;
              padding-bottom: 5px;
              display: block;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  .brd_footer {
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    border-top: solid 1px rgba(255, 255, 255, 0.6);
    margin-top: 20px;
    padding: 20px 0;

    .brd-copyright {
      color: #fff;
      font-size: 1rem;
    }
    .f-right-temporary{
      display: flex;
      align-items: center;
      .terms-link-temporary{
        display: flex;
        margin-right: 15px;
        ul{
          list-style-type: none;
          margin: 0;
          padding: 0;
          display: flex;
          li{
            padding: 0 10px;
            a{
              color: #FFF;
              font-size: 1rem;
              &:hover{
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
    .brd-right {
      display: flex;
      align-items: center;

      .brd-links {
        margin-right: 3rem;
        a {
          color: #fff;
          font-size: 1rem;
          margin: 0 10px;
          &:hover {
            color: #000;
            text-decoration: underline;
          }
        }
      }

      .brd-social {
        .fa-stack {
          font-size: 1.5rem;
          .fa-stack-2x {
            color: #fff;
            transition: all 0.2s ease;
            opacity: 0.7;
          }
          .fa-stack-1x {
            color: #000;
            transition: all 0.2s ease;
            font-size: 1rem;
          }
        }
      }
    }
  }
}


.customer-video {
  width: 70%;
}

.app-buttons {
  display: flex;
  gap: 30px;
}


.general-section {
  padding: 60px 0;
}

.list-simple {
  &.list-style-none {
    list-style-type: none;
  }

  padding-left: 20px;

  li {
    margin-bottom: 10px;
    color: #252525;

    strong {
      // font-family: "SegoeUIBold";
    }
  }
}

.highlight-txt {
  color: #007bff;
}

.highlight-txt {
  color: #007bff;

  &:hover {
    color: #007bff;
  }
}

.privacy_header {
  // text-align: center;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
  -webkit-box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
  -moz-box-shadow: 0 4px 8px 0 rgba(10, 22, 70, 0.15);
  background-color: #fff;
  padding: 30px;

  h2 {
    color: #000080;
    font-weight: 800 !important;
    font-size: 120px;
    margin-bottom: 40px;
  }

  .privacy-img {
    margin-bottom: 40px;
    max-width: 100%;
    height: auto;
  }

  h3 {
    color: #252525;
    font-weight: 800 !important;
    font-size: 30px;
    margin-bottom: 10px;
  }

  h4 {
    color: #252525;
    font-weight: 800 !important;
    font-size: 24px;
    line-height: 2rem;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  h5 {
    color: #252525;
    font-weight: 800 !important;
    font-size: 20px;
    line-height: 2rem;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  h6 {
    color: #252525;
    font-weight: 800 !important;
    font-size: 16px;
    line-height: 1.5rem;
    margin-bottom: 10px;
    padding-top: 10px;
  }

  p {
    color: #252525;
    font-weight: 400 !important;

    strong {
      // font-family: "SegoeUIBold";
      font-family: Nunito Sans, sans-serif !important;
    }
  }
}

.table-simple {
  padding: 20px 0;
  font-family: Nunito Sans, sans-serif !important;

  thead {
    background: #000080;
    color: #fff;

    tr {
      th {
        width: 410px;
        vertical-align: middle;

        &:first-child {
          width: 260px;
        }
      }
    }
  }
}

.pkg_fb_connect .modal-dialog {
  max-width: 600px;
}

.abc-checkbox input[type=checkbox],
.abc-checkbox input[type=radio] {
  position: static;
  margin-left: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 1;
}

.abc-checkbox label {
  cursor: pointer;
  display: inline;
  vertical-align: top;
  position: relative;
  padding-left: 5px;
}

.abc-checkbox label:before {
  cursor: pointer;
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 2px;
  left: 0;
  margin-left: -1.25rem;
  border: 1px solid #c1ccd3;
  border-radius: 3px;
  background-color: #fff;
  transition: border .15s ease-in-out, color .15s ease-in-out;
}

.abc-checkbox-primary input[type=checkbox]:checked+label:before,
.abc-checkbox-primary input[type=radio]:checked+label:before {
  background-color: #000080;
  border-color: #000080;
}

.abc-checkbox label:after {
  cursor: pointer;
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 2px;
  margin-left: -1.25rem;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 11px;
  color: #495057;
}

.abc-checkbox-primary input[type=checkbox]:checked+label:after,
.abc-checkbox-primary input[type=radio]:checked+label:after {
  color: #fff;
}

.abc-checkbox input[type=checkbox]:checked+label:after,
.abc-checkbox input[type=radio]:checked+label:after {
  font-family: FontAwesome;
  content: "";
}

.promo-err-box {
  min-height: 22px;
}

.insta-list {
  margin-left: 20px;
}

.promo_msg {
  font-family: "SegoeUIBold";
  margin-bottom: 8px;
}

.promo_code_ift .promo_iner {
  display: flex;
}

.promo_code_ift .promo_iner .form-control {
  margin-right: 20px;
}

.promo_code_ift .promo_iner .btn {
  margin-right: 0 !important;
}

.package-tabs-main {
  .ant-tabs-nav {
    margin-bottom: 0px;

    .ant-tabs-nav-wrap {
      padding-bottom: 30px;

      .ant-tabs-ink-bar {
        height: 100%;
        z-index: 0;
        border-radius: 2rem;
      }
    }
  }

  .ant-tabs-tab {
    padding: 0.5rem 1.5rem;
    margin: 0 10px 0 0px;
    min-width: 80px;
    justify-content: center;
    font-weight: 600 !important;
    font-family: Nunito Sans, sans-serif !important;
    font-size: 16px;

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: white;
        z-index: 10;
      }
    }
  }
}

.package-discpunt {
  background: green;
  border-radius: 100px;
  color: #fff;
  font-family: SegoeUIBold;
  font-size: 16px;
  font-weight: 600;
  padding: 4px 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  /* width: 70px; */
  /* height: 70px; */
  text-align: center;
}

.roud-link {
  background-color: #000080;
  color: #FFF;
  font-family: Nunito Sans, sans-serif !important;
  padding: 10px 35px;
  font-weight: 700;
  border-radius: 37px;
  display: inline-block;
  margin-top: 25px;
  font-size: 16px;

  &:hover {
    background-color: #000;
    color: #FFF;
  }
}

.signup-border-content {
  width: 100%;
  margin: 0 auto 20px auto;
  // margin-top: 40px;
  padding: 50px 0;
  // border-top: 1px solid #434433;
  // border-bottom: 1px solid #434433;
  background: #f1f1f1;

  h2 {
    font-size: 30px;
    font-weight: 800 !important;
  }
}

.logos-sec-main {
  width: 100%;
  padding: 60px 0;
  background-color: #e7e6e6;

  .logos-container {
    width: 100%;

    h2 {
      font-size: 40px;
      font-weight: 800 !important;
    }

    .brand-boxes {
      align-items: center;
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 0 8px rgba(10, 22, 70, .15);
      display: inline-flex;
      min-width: 100%;

      .brand-boxes-logos {
        flex-basis: 88px;
        flex-grow: 0;
        flex-shrink: 0;
        height: 88px;
        overflow: hidden;
        padding: 4px;
        position: relative;
        transition: box-shadow .25s;
        width: 88px;

        img {
          border-radius: 6px;
          display: block;
          height: 100%;
          object-fit: contain;
          object-position: center;
          width: 100%;
        }
      }

      .brand-user {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        margin-left: 10px;
        min-width: 0;
        width: 100%;

        .brnd-user-title {
          align-items: center;
          display: flex;

          span {
            color: #000;
            font-family: Nunito Sans, sans-serif !important;
            font-weight: 700;
          }
        }
      }

    }


  }

}


.creator-section {
  padding: 60px 0;

  h2 {
    font-size: 30px;
    font-weight: 800 !important;
  }
}

.creator-box {
  .creator-img {
    width: 200px;
    height: 200px;
    overflow: hidden;
    margin: 0 auto 15px auto;
    border-radius: 100%;
    display: flex;

    img {
      border-radius: 100%;
    }
  }
}

.mobile-menu-ifti {
  display: none;
}


@media screen and (min-width: 1440px) {
  .bioshop-sec-main .bioshop-container .bioshop-box .public-why-home-img {
    width: 70%;
  }

  .mid-width {
    -webkit-padding-end: 144px;
    -webkit-padding-start: 144px;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1632px;
    padding-inline-end: 144px;
    padding-inline-start: 144px;
  }

  .max-1140 {
    max-width: 1140px !important;
  }
}

@media screen and (min-width: 600px) {
  .mid-width {
    -webkit-padding-end: 48px;
    -webkit-padding-start: 48px;
    padding-inline-end: 48px;
    padding-inline-start: 48px;
  }
}

@media screen and (min-width: 100px) {
  .mid-width {
    -webkit-padding-end: 20px;
    -webkit-padding-start: 20px;
    padding-inline-end: 20px;
    padding-inline-start: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .general-section {
    padding: 40px 0;
  }

}

@media only screen and (min-width: 100px) {

  }


@media only screen and (max-width: 990px) {
  .middle-width1 {
    padding-inline-end: 20px!important;
    padding-inline-start: 20px!important;
}
  .mobile-menu-ifti{
    display: block;
    // position: absolute;
    left: 0;
    width: 32px;
    height: 32px;
    border: solid 1px #CCC;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .barsBtn{
        img{
            width: 16px;
            display: block;
        }
    }
}

.public-header .public-header-content {
  padding: 12px 0;
}


.ant-drawer-content{
  .ant-drawer-header{
      padding-left: 16px;
      padding-right: 16px;
      .ant-drawer-header-title{
          button{
              padding-left:0;
              font-size: 18px;
              color: #000;
          }
      }
  }
}

.public-menu {
&.hide-mobile {
  display: none;
}
}

.public-menu  {
  > ul {
    flex-direction: column;
    > li {
      padding: 16px 0;
      > a{
          font-size: 16px;
          font-weight: 600;
      }
    }
  }
}

.sign-mobile-drawer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  h4{
      font-size: 1.3rem;
      font-weight: 400 !important;
      margin-bottom: 16px;
      text-align: center;
      span{
          font-weight: 700;
      }
  }
  .sign-mb-btns{
      display: flex;
      width: 100%;
      justify-content: center;
      grid-gap: 16px;
      justify-content: space-between;
      text-align: center;
      border-bottom: solid 1px #b9b9b9;
      padding-bottom: 32px;
      margin-bottom: 32px;
      a{
          display: inline-flex;
          flex: 1 1 0%;
          border-radius: 100px;
          padding-left: 16px;
          padding-right: 16px;
          padding-top: 10px;
          padding-bottom: 10px;
          transition: all 200ms ease 0s;
          box-shadow: 0px 4px 8px #0a164626;
          justify-content: center;
          color: #FFF;
          font-family: Nunito Sans,sans-serif!important;
          font-weight: 700;
          background-color: #010b40;
          font-size: 16px;
      }
  }
}

}



@media only screen and (max-width: 767px) {
  .privacy_header {
    h5 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700 !important;
    }
  }

  .public-header {
    // padding: 7px 0;
    padding: 0px 0;

    .mid-width {
      -webkit-padding-end: 10px;
      -webkit-padding-start: 10px;
      padding-inline-end: 10px;
      padding-inline-start: 10px;
    }

    .public-logo {
      img {
        width: 70px;
      }
    }


  }


  .customer-video {
    width: 100%;
  }

  .public-header {
    padding: 7px 0;

    .mid-width {
      -webkit-padding-end: 10px;
      -webkit-padding-start: 10px;
      padding-inline-end: 10px;
      padding-inline-start: 10px;
    }

    .public-menu {
      ul {
        padding: 0;

        li {
          padding: 0 5px;

          a {
            font-size: 12px;
          }
        }
      }
    }
  }

  .default-btn {
    &.rounded {
      font-size: 12px;
      margin: 0 0 0 5px;
      padding: 5px 10px 0px 10px !important;
      height: 30px;
    }
  }


  .brd_footer_main{
    display: none;
}


.mobile_footer_ifti{
  display: block;
  background-color: #252525;

  .ant-collapse{
      background-color: #252525;

      .ant-collapse-header{
          font-family: Nunito Sans,sans-serif!important;
          text-transform: uppercase;
          color:#FFF;
          opacity: .7;
      }
      .ant-collapse-content{
          background-color: #252525;
          border: none;
          .ant-collapse-content-box{
              padding-top: 0;

              .brd-footer-box{
                  ul{
                      list-style-type: none;
                      margin: 0;
                      padding: 0;

                      li{
                          width: 100%;

                          a{ 
                              color: #fff;
                              display: block;
                              font-size: 14px;
                              padding-bottom: 5px;
                              padding: 10px 0;
                          }
                      }
                  }
              }
          }
      }
  }
  .brd_footer{
      width: 100%;
      justify-content: space-between;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0;

      .brd-right{
          align-items: center;
          display: flex;

          .brd-social{
              display: flex;
              flex-direction: row;
              grid-column-gap: 10px;

              .fa-stack{
                  font-size: 1.5rem;
                  .fa-stack-2x{
                      color: #FFF;
                      transition: all 0.2s ease;
                      opacity: 0.7;
                  }
                  .fa-stack-1x {
                      color: #000;
                      transition: all 0.2s ease;
                      font-size: 1rem;
                  }
              }
          }
      }
      .brd-copyright{
          color: #fff;
          font-size: 1rem;
          margin-top: 10px;
      }
  }
}


}

@media only screen and (max-width: 500px) {
  .public-header {
    .default-btn.rounded {
      padding: 5px 7px 0px 7px !important;
    }

    .public-header-content {
      gap: 0;

      .header-left {
        gap: 10px;

        .public-menu {
          width: 120px;

          ul {
            padding: 0;

            li {
              a {
                font-size: 14px;
              }

              padding: 0 10px;

              &:first-child {
                // padding-left: 0;
              }

              &:last-child {
                padding-right: 0;
              }
            }
          }
        }
      }
    }
  }
}



@media screen and (min-width: 100px) {
  .midd-width-footer {
    padding-inline-end: 20px;
    padding-inline-start: 20px;
  }
}

@media screen and (min-width: 600px) {
  .midd-width-footer {
    padding-inline-end: 24px;
    padding-inline-start: 24px;
  }
}
@media screen and (min-width: 1440px) {
  .midd-width-footer {
    max-width: 100%;
    padding-inline-end: 24px;
    padding-inline-start: 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;
    margin: 0 auto;
  }
  .max-1140 {
    max-width: 1140px !important;
  }
}

@media screen and (min-width: 1440px) {
  .midd-width-footer {
    max-width: 100%;
    padding-inline-end: 24px;
    padding-inline-start: 24px;
    display: flex;
    justify-content: space-between;
    box-sizing: content-box;
    margin: 0 auto;
  }

}