.create-event-top-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  padding: 0 15px;

  .top-area-box {
    .prev-next-link {
      color: #7a7a7a;
      font-size: 16px;
      display: inline-block;
      align-items: center;

      svg {
        margin-right: 10px;
        font-size: 13px;

        &.fa-bullhorn {
          font-size: 15px;
        }
      }

      span {
        text-decoration: underline;
      }
    }
  }
}

.prev-next-link {
  color: #7a7a7a;
  font-size: 16px;
  display: inline-block;
  align-items: center;
  cursor: pointer;

  svg {
    margin-right: 7px;
    font-size: 13px;

    &.fa-bullhorn {
      font-size: 15px;
    }
  }

  span {
    text-decoration: underline;
  }
}


.section-title {
  color: #252525;
  font-size: 22px;
  line-height: 2rem;
  margin-bottom: 10px;
  padding: 10px 0 10px;

  span {
    color: #6270bd;
  }
}

.create-event-wrapper {
  display: flex;
  justify-content: space-between;
  // gap: 25px;

  .create-event-box-left {
    display: flex;
    flex: 0 0 70%;
    flex-direction: column;
    max-width: 70%;
    background-color: #ffffff;
    padding: 20px 40px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);

    &.full-width {
      flex: 0 0 100%;
      max-width: 100%;
    }

    .guide-column {
      border-right: 3px solid #ededed;

      h5 {
        // margin-bottom: 20px;
      }

      p {
        line-height: 20px;
        color: grey;
        font-size: 13px;
      }
    }

  }

  .create-event-box-right {
    display: flex;
    flex: 0 0 30%;
    flex-direction: column;
    max-width: 30%;
    padding: 0 25px;
  }
}

.discover-txt {
  display: flex;
  align-items: center;
  gap: 15px;

  a {
    margin: 0;
    padding: 0;
    text-decoration: underline;
    color: grey;
    font-size: 13px;

    &:hover {
      text-decoration: none;
    }
  }
}

.event-url-box {
  border: 1px solid #e9ecef;
  background-color: #f5f5f5;
  border-radius: 0.375rem;
  padding: 10px;

  h3 {
    font-family: Nunito Sans, sans-serif !important;
    font-size: 14px;
    font-weight: 600;
  }

  .condition-txt {
    font-size: 11px;
    padding: 5px 0 30px 0;
    margin: 0;
    color: grey;
  }

  .yellow-warning-txt {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    color: #fdb927;
    font-size: 13px;

    svg {
      margin-top: 5px;
    }
  }

}

.create-event-box-right {
  .preview-hd {
    color: #252525;
    font-size: 18px;
    padding: 0 0 5px;
    text-align: center;
  }

  .ant-tabs .ant-tabs-tab {
    color: #a9a19c;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000080;
  }

  .ant-tabs-tab:hover {
    color: #000080;
  }

  .ant-tabs .ant-tabs-ink-bar {
    background: #000080;
  }

  .preview-tabs-main {
    .ant-tabs-content-holder {
      margin: 10px auto;
      background-color: #c8c8c8;
      padding: 14px 12px;
      border-radius: 25px;
      min-height: 654px;
      color: white;
      min-width: 337px;
      max-width: 337px;
    }

    .preview-mobile-box {
      background-color: #170a1c;
      padding: 22px 12px 14px 12px;
      border-radius: 25px;
      min-height: 626px;
      background-image: url(../images/mob-top-sign.png);
      background-position: center top 6px;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;

      .preview-box {
        width: 100%;
        background-color: #2c2c2c;
        border-radius: 25px;
        height: 575px;

        .preview-img {
          border-radius: 25px 25px 0 0;
          width: 100%;
          height: 162px;
          line-height: 158px;
          overflow: hidden;
          text-align: center;
          background-color: #ffffff;
          position: relative;

          // &:before {
          //   position: absolute;
          //   content: "";
          //   background-color: #2c2c2c;
          //   left: 0px;
          //   bottom: 0px;
          //   width: 100%;
          //   height: 1px;
          // }

          img {
            // border-radius: 25px 25px 0 0;
            max-width: 100%;
            max-height: 100%;

          }

          &.sample-image {
            // background-color: #2c2c2c;
            background: #00008a;
            background: -webkit-gradient(left top,left bottom,color-stop(0,#00008a),color-stop(100%,#000c40));
            background: linear-gradient(180deg,#00008a 0,#000c40);

            img {
              height: 100%;
            }
          }
        }

        .preview-content-main {
          padding: 15px 15px 25px 15px;
          text-align: center;

          .preview-title {
            color: #ffffff;
            font-size: 20px;
            // word-break: break-all;
            word-break: break-word;
            // padding-bottom: 10px;
          }

          .preview-date {
            color: #ffffff;
            font-size: 16px;
            padding-bottom: 20px;
          }

          .preview-txt {
            color: #ffffff;
            font-size: 16px;
            padding-bottom: 20px;
          }
        }
      }
    }
  }
}

.preview-content-main-modal {
  padding: 15px 15px 25px 15px;
  text-align: center;
  background-color: #2c2c2c;
  color: #ffffff;

  .event-name-hd {
    color: #ffffff;
    font-size: 25px;
  }
  .preview-title {
    color: #ffffff;
    font-size: 20px;
    // padding-bottom: 10px;
  }

  .preview-date {
    color: #ffffff;
    font-size: 16px;
    padding-bottom: 20px;
  }

  .preview-txt {
    color: #ffffff;
    font-size: 16px;
    padding-bottom: 20px;
  }
}


.sku-field {
  .ant-form-item-label {
    text-align: left;
  }
}

.choose-event-boxes {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin: 0 0 20px 0;

  .choose-box {
    text-align: center;
    background-color: #ffffff;
    padding: 40px 50px;
    border-radius: 5px;
    box-shadow: 0 0 2px rgb(0 0 0 / 26%);

    .event-img {
      margin: 0 0 30px 0;
      ;
    }

    h3 {
      color: #252525;
      font-size: 22px;
      margin: 0;
      padding: 0 0 15px;
    }

    p {
      color: grey;
      font-size: 16px;
      margin: 0;
      padding: 0 0 30px;
    }

  }
}

.event-steps {
  display: inline-block;
  list-style-type: none;
  margin: 0 0 5px 0;
  padding: 0;

  li {
    position: relative;
    float: left;
    padding: 0 25px;
    width: 33%;
    text-align: center;

    &:before {
      position: absolute;
      content: "";
      background-color: #ededed;
      width: 100px;
      left: -55px;
      top: 30%;
      height: 4px;
      border-radius: 100px;
      opacity: 0.3;
    }

    &:first-child::before {
      display: none;
    }

    .step-img {
      opacity: 0.3;
      max-height: 55px;
    }

    .setup-hd {
      opacity: 0.3;
      padding-top: 15px;
    }

    &.active-step {
      &:before {
        background-color: #000080;
        opacity: 1;
      }

      .step-img {
        opacity: 1;
      }

      .setup-hd {
        opacity: 1;
      }
    }
  }
}

.event-stepss {
  margin-bottom: 20px;

  .ant-steps-item {
    opacity: 0.3;

    &:hover {
      .setup-hd {
        color: black;
      }
    }

    &.ant-steps-item-active {
      opacity: 1;
    }

  }

  .ant-steps-item-title {
    display: flex;
    align-items: center;
    flex-direction: column;

    &:after {
      top: 40%;
      height: 3px;
      background: #ededed;
      // opacity: 0.5;
    }
  }

  .ant-steps-item-icon {
    display: none;
  }

  img.step-img {
    height: 55px;
  }

  .setup-hd {
    text-align: center;
    font-size: 14px;
  }
}

.event-stepss .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title,
.event-stepss .ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.event-stepss .ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description {
  color: #000080 !important;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: #000080 !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #000080;
}

.ant-btn-primary {
  border-color: #000080;
  background: #000080;
}

.ant-picker-ok .ant-btn-primary:hover,
.ant-picker-ok .ant-btn-primary:focus {
  border-color: #000080;
  background: #000080;
}

.ant-picker-now {
  a {
    color: #000080;
  }
}

.ant-image-preview-img-wrapper {
  .ant-image-preview-img {
    background: #000080;
  }
}

.span-inner-initial {
  span {
    line-height: initial;
  }
}

.label-padb-0 {
  .ant-form-item-label {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 1366px) {
  .event-steps {
    li {
      &:before {
        width: 70px;
        left: -25px;
      }

    }
  }

  .create-event-wrapper {
    .create-event-box-left {
      flex: 0 0 65%;
      max-width: 65%;
      padding: 20px 20px;
    }

    .create-event-box-right {
      flex: 0 0 35%;
      max-width: 35%;
      padding: 0 15px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .create-event-wrapper {
    .create-event-box-left {
      flex: 0 0 65%;
      max-width: 65%;
    }

    .create-event-box-right {
      flex: 0 0 35%;
      max-width: 35%;
    }
  }

  .form-styling {

    &.date-time-main .datepicker .ant-picker {
      width: initial;
    }
  }
}

@media only screen and (max-width: 991px) {
  .create-event-wrapper {
    flex-direction: column-reverse;
    gap: 25px;

    .create-event-box-left {
      flex: 0 0 100%;
      max-width: 100%;

      .guide-column {
        border-right: 0px;

        h5 {
          margin-bottom: 5px;
        }
      }
    }

    .create-event-box-right {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

}


@media only screen and (max-width: 767px) {
  .event-steps {
    li {
      .step-img {
        max-height: 35px;
      }

      &:before {
        width: 15px;
        left: 0px;
      }

    }
  }

  .choose-event-boxes {
    gap: 20px;
    margin: 0 0 0px 0;
    flex-direction: column;

    .choose-box {
      padding: 20px 20px;

      .event-img {
        margin: 0 0 15px 0;
        width: 80px;
      }

      h3 {
        font-size: 18px;
        padding: 0 0 10px;
      }

      p {
        font-size: 14px;
        padding: 0 0 20px;
      }

    }
  }

  .create-event-top-area {
    // flex-direction: column;
    // justify-content: start;
    // align-items: start;
    padding: 0;

    .top-area-box {
      .prev-next-link {
        font-size: 13px;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .event-stepss {
    img.step-img {
      height: 25px;
    }

    .setup-hd {
      font-size: 13px;
    }
  }
}

@media only screen and (max-width: 400px) {

  .create-event-wrapper {
    .create-event-box-right {
      .preview-tabs-main {
        .ant-tabs-content-holder {
          min-width: 320px;
          max-width: 100%;

        }
      }
    }
  }
}