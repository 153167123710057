#reportOrderInput::-webkit-outer-spin-button,
#reportOrderInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.sales-page-main {
  .filter-btns {
    font-size: 13px;
    padding: 5px 10px 0px 10px;
    font-weight: 400 !important;
    width: initial;
    min-width: 100px;
    height: 35px;
    text-shadow: none;

    &:hover {
      color: #ffffff;
      background-color: #798892;
      border: 1px solid #798892;
      box-shadow: none;
      outline: none;
    }

    &:focus {
      color: #ffffff;
      background-color: #798892;
      border: 1px solid #798892;
    }
  }

  .fltr-hpr {
    padding: 6.4px 25px !important;
    height: 40px !important;
    max-width: 100% !important;
    min-width: 50% !important;
    text-shadow: none;

    &:hover {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
      box-shadow: none;
      outline: none;
    }

    &:focus {
      color: #ffffff;
      background-color: #000080;
      border: 1px solid #000080;
    }
  }

  .fltr-hpr {
    &.m-width-100 {
      min-width: 100px !important;
    }
  }
}

.fltr-hpr {
  &.m-width-100 {
    min-width: 100px !important;
  }
}

.aff-payment {
  .transactions-box {
    border: 1px solid #d6dee5;
    box-shadow: 0 0 2px 1px #e4e7ed;
    background: #fff;

    .table_heading {
      background: #000080;
      color: #fff;
    }

    thead {
      tr {
        th {
          text-transform: none;
          font-family: Nunito Sans, sans-serif;
          font-weight: 700;
          padding: 0.3rem 0.75rem;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 0.1rem 0.75rem;
          font-size: 12px;
          vertical-align: middle;

          &.sales-event-link {
            a {
              color: #000080;

              &:hover {
                color: #134a7c;
              }
            }

            .blue-link {
              color: #007bff;
            }
          }

          .order-number-btn {
            cursor: pointer;
          }
        }

        &:hover {
          background-color: #f6f6f7;
        }
      }
    }
  }
}

.custom-paginate-new {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.inf-hd1 {
  color: #6d5f58;
  font-weight: 700;
}

.blue-link {
  color: #007bff;
}

.sales-amount {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 10px;
  justify-content: center;
  margin-top: -1px;
  background: #8b7f7940;
  border-radius: 100px;
  padding: 2px 2px;
}

.custom-paginate-new>.active>a {
  background-color: #000080;
  border-color: #000080;
  color: #fff;
}

.custom-paginate-new>li>a {
  border: 1px solid #000080;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.custom-paginate-new>.active>a,
.custom-paginate-new>.active>a:focus,
.custom-paginate-new>.active>a:hover,
.custom-paginate-new>.active>span,
.custom-paginate-new>.active>span:focus,
.custom-paginate-new>.active>span:hover {
  background-color: #fff !important;
  border: 1px solid #000080 !important;
  outline: none;
  color: #000080 !important;
}

.custom-paginate-new>li:first-child>a,
.custom-paginate-new>li:first-child>span,
.custom-paginate-new>li:last-child>a,
.custom-paginate-new>li:last-child>span {
  border-radius: unset;
}

.custom-paginate-new .custom-paginate-link,
.custom-paginate-new .custom-paginate-next,
.custom-paginate-new .custom-paginate-prev {
  background-color: #000080;
  color: #fff;
}

.custom-paginate-new .custom-paginate-link {
  min-width: 40px !important;
  height: 40px;
  line-height: 2 !important;
}

.custom-paginate-new .page-item.disabled .page-link {
  border: 1px solid #d6dee5;
  color: #ccc;
}

.custom-paginate-new .custom-paginate-next,
.custom-paginate-new .custom-paginate-prev {
  min-width: 40px !important;
  height: 40px;
  line-height: 2 !important;
}

.custom-paginate-new .custom-paginate-prev:before {
  content: "\f053";
  font-family: FontAwesome;
}

.custom-paginate-new .custom-paginate-next:before {
  content: "\f054";
  font-family: FontAwesome;
}

.sales-summery {
  // margin-top: -56px;
  margin-top: 0;
}

.summarry-box {
  border: 1px solid #d6dee5;
  background: #fff;
  padding: 10px;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
}

.summarry-box h5 {
  border-bottom: 1px solid #d6dee5;
  padding-bottom: 10px;
}

.summarry-box {
  &.new-style {
    .summary-hd {
      border-bottom: 0;
      padding-bottom: 5px;
      font-size: 18px;
      border-bottom: 1px solid #e1e1e1;
    }
  }
}

.box-hd-top {
  border-bottom: 0;
  padding-bottom: 5px;
  font-size: 18px;
  border-bottom: 1px solid #e1e1e1;
}

.summarry-box.new-style .summary-detail-box {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #ede9e9;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 4px;
}

.summarry-box.new-style .summary-detail-box:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.summarry-box.new-style.top-space {
  margin-top: -60px;
}

.summary-detail-box .count {
  font-size: 12px;
  margin-bottom: 3px;
}

.summary-detail-box .count-title {
  color: #798892;
  font-size: 12px;
  margin-bottom: 3px;
}

.summarry-box.new-style .summary-detail-box:last-child .count {
  margin-bottom: 0;
}

.summarry-box.new-style .summary-detail-box:last-child .count-title {
  margin-bottom: 0;
}

.fulfil-status {
  // background-color: #ffc107;
  // background-color:#ffea8a;
  color: #000000;
  border-radius: 100px;
  display: inline-block;
  padding: 2px 10px;
  font-size: 12px;
}

.filled-dot {
  width: 8px;
  height: 8px;
  background-color: #5c5f62;
  border-radius: 100px;
  margin-right: 5px;
}

.border-dot {
  width: 9px;
  height: 9px;
  background-color: transparent;
  border: 2px solid #5c5f62;
  border-radius: 100px;
  margin-right: 5px;
}

.unfulfilled {
  background-color: #ffea8a;
  color: #000000;
}

.fulfilled {
  background-color: #28a745;
  color: #ffffff;
}

.refunded {
  background-color: #e4e5e7;
  color: #000000;
}

.partially-fulfilled {
  background-color: #ffd79d;
  color: #000000;
}

.scroll-area {
  overflow: hidden;
  max-height: 500px;
  padding-right: 10px;
}

.order-detail-title-area {
  padding: 4px 0 0 45px;
  position: relative;
  margin-bottom: 20px;

  .back-btn {
    position: absolute;
    left: 0;
    top: 0;
    margin: 0;
  }

  .order-date {
    font-size: 13px;
  }

  .title-info-main {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding-bottom: 5px;

    .order-number {
      font-family: Nunito Sans, sans-serif;
      font-weight: 700;
      font-size: 17px;
    }

    .fulfil-status {
      // background-color: #ffc107;
      // background-color:#ffea8a;
      color: #000000;
      border-radius: 100px;
      display: inline-block;
      padding: 2px 10px;
      font-size: 12px;
    }

    .unfulfilled {
      background-color: #ffea8a;
      color: #000000;
    }

    .fulfilled {
      background-color: #28a745;
      color: #ffffff;
    }

    .partially-fulfilled {
      background-color: #ffd79d;
      color: #000000;
    }

    .paid {
      background-color: #e4e5e7;
      color: #000000;
    }

    .unpaid {
      background-color: #ffd79d;
      color: #000000;
    }
  }
}

.order-detail-box {
  background-color: white;
  border-radius: 5px;
  // box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  box-shadow: 0 0 0.2125rem #1718180d, 0 0.0625rem 0.125rem #00000026;
  border: 1px solid #e1e3e5;
  padding: 20px 25px;
  margin-bottom: 15px;
}

.status-heading {
  // position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito Sans, sans-serif !important;

  .txt {
    line-height: inherit;
  }
}

.order-details-listing-main {
  position: relative;
  padding: 20px 0;
  border-bottom: 1px solid #e1e3e5;

  .left-content {
    position: relative;
    padding-left: 70px;

    .order-pic-wrapper {
      position: absolute;
      left: 15px;
      top: 0px;

      .order-pic-main {
        width: 38px;
        height: 38px;
        border-radius: 5px;
        position: relative;
        border: 1px solid #e1e1e1;

        .order-count {
          width: 25px;
          height: 20px;
          text-align: center;
          line-height: 20px;
          border-radius: 100%;
          background-color: #e4e5e7;
          color: black;
          font-size: 12px;
          position: absolute;
          right: -12px;
          top: -12px;
          z-index: 99;
        }

        .order-pic {
          width: 100%;
          overflow: hidden;

          img {
            bottom: 0;
            left: 0;
            margin: auto;
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }

    .order-name {
      a {
        color: #2c6ecb;
        text-decoration: underline;
        font-size: 14px;

        &:hover {
          color: #1f5199;
          text-decoration: none;
        }
      }
    }

    .order-style {
      font-size: 14px;
    }

    .order-sku {
      font-size: 14px;
    }

    .order-discount {
      font-size: 14px;
    }
  }

  .order-price-status {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    .cut-price {
      color: #2c6ecb;
    }
  }
}

.order-details-listing-main:last-child {
  border-bottom: 0px;
  padding-bottom: 0px;
}

.customer-box {
  padding: 20px 0px;

  .status-heading {
    padding: 0 20px 0 20px;
    margin-bottom: 0;
  }

  .customer-info {
    padding: 15px 20px;
    border-bottom: 1px solid #e1e3e5;

    .customer-hd {
      font-size: 13px;
      font-weight: 700;
      text-transform: uppercase;
      font-family: Nunito Sans, sans-serif !important;
      padding-bottom: 5px;
    }

    .customer-name {
      font-size: 14px;
    }

    .number-of-orders {
      font-size: 14px;
    }

    .customer-link {
      a {
        color: #2c6ecb;
        text-decoration: none;
        font-size: 14px;

        &:hover {
          color: #1f5199;
          text-decoration: none;
        }
      }
    }

    &.last-box {
      border-bottom: 0px;
      padding-bottom: 0px;
    }
  }
}

.paid-rows {
  font-size: 14px;
}

.paid-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e1e3e5;
}

.paid-mid-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding: 3px 0;

  .multi-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;

    .left-hd {
      min-width: 120px;
    }
  }

  .fw-bold {
    font-weight: 700;
    font-family: Nunito Sans, sans-serif !important;
  }
}

.paid-bottom-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding-top: 15px;
  margin-top: 10px;
  border-top: 1px solid #e1e3e5;
}

.td-width-40 {
  min-width: 40px;
  max-width: 40px;
  width: 40px;
}

.td-width-50 {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}

.td-width-60 {
  min-width: 60px;
  max-width: 60px;
  width: 60px;
}

.td-width-70 {
  min-width: 70px;
  max-width: 70px;
  width: 70px;
}

.td-width-80 {
  min-width: 80px;
  max-width: 80px;
  width: 80px;
}

.td-width-90 {
  min-width: 90px;
  max-width: 90px;
  width: 90px;
}

.td-width-100 {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}

.td-width-110 {
  min-width: 110px;
  max-width: 110px;
  width: 110px;
}

.td-width-120 {
  min-width: 120px;
  max-width: 120px;
  width: 120px;
}

.td-width-130 {
  min-width: 130px;
  max-width: 130px;
  width: 130px;
}

.td-width-140 {
  min-width: 140px;
  max-width: 140px;
  width: 140px;
}

.td-width-150 {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}

.td-width-200 {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}

.td-width-250 {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

.td-width-300 {
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}

.code-activated-popup {
  .order-detail-title-area {
    margin-bottom: 10px;
    padding: 4px 0 0 0;
    position: relative;
    display: flex;
    justify-content: space-between;

    .order-num {
      font-family: Nunito Sans, sans-serif;
      font-size: 14px;
      font-weight: 700;
      display: flex;
      gap: 5px;
    }

    .order-name {
      font-weight: 600;
    }

    .order-date {
      font-size: 14px;
    }
  }

  .order-sum {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e1e3e5;
    border-radius: 5px;
    box-shadow: 0 0 0.2125rem rgb(23 24 24 / 5%),
      0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
    padding: 15px;

    .paid-mid-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3px 0;

      .multi-content {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: space-between;

        .left-hd {
          min-width: 120px;
        }
      }

      .fw-bold {
        font-family: Nunito Sans, sans-serif !important;
        font-weight: 700;
      }
    }

    .paid-bottom-row {
      border-top: 1px solid #e1e3e5;
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: space-between;
      margin-top: 5px;
      padding-top: 5px;
    }
  }

  .transactions-box {
    background: #fff;
    border: 1px solid #d6dee5;

    thead {
      background: #000080;
      color: #fff;

      tr {
        th {
          font-family: Nunito Sans, sans-serif;
          font-weight: 700;
          padding: 0.3rem 0.75rem;
          text-transform: none;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 12px;
          padding: 0.1rem 0.75rem;
          vertical-align: middle;
        }
      }
    }
  }
}

.blue-link {
  color: #007bff;
}

.conn-set-inner {
  flex-basis: 100%;
  box-shadow: 0px 0px 2px 1px #e4e7ed;
  background: #fff;
  margin-bottom: 24px;
  position: relative;
}

.affiliate-wallet {
  padding: 15px;
  text-align: center;
}

.affiliate-wallet .aff-amount {
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  font-size: 30px;
}

.amount-box-deposit {
  padding: 15px;
}

@media only screen and (max-width: 1200px) {
  .sales-summery {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 991px) {
  .summarry-box.new-style.top-space {
    margin-top: 0px;
  }

  .sales-page-main {
    .fltr-hpr {
      min-width: 120px !important;
      max-width: 120px !important;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .affiliate-wallet {
    padding: 10px;
  }

  .affiliate-wallet .aff-amount {
    font-size: 20px;
  }
}


@media only screen and (max-width: 767px) {
  .code-activated-popup {
    .order-detail-title-area {
      flex-direction: column;
    }
  }
}