.scrollbar-style::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

.scrollbar-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.scrollbar-style::-webkit-scrollbar-thumb,
.scrollbar-style::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.scrollbar-style-parent .ant-table-content::-webkit-scrollbar {
  width: 10px;
  height: 12px;
}

.fw-bold {
  font-weight: 700;
  font-family: Nunito Sans, sans-serif !important;
}

.scrollbar-style-parent .ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.scrollbar-style-parent .ant-table-content::-webkit-scrollbar-thumb,
.scrollbar-style-parent .ant-table-content::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-auto {
  overflow-y: auto;
}

.color-primary {
  color: #000080;
}

.default-btn {
  font-family: Nunito Sans, sans-serif !important;
  font-size: 15px;
  font-weight: 700;
  margin: 0 5px 0;
  border-radius: 3px;
  color: #ffffff;
  background-color: #000080;
  border: 1px solid #000080;
  min-width: 120px;
  height: 45px;
  padding: 6.4px 25px;
  align-items: center;
  -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
  filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));

  &.width-auto {
    min-width: auto;
  }

  svg {
    position: relative;
    // top: -1px;
  }

  svg+span {
    margin-left: 8px;
    line-height: initial;
  }

  &:hover {
    color: #ffffff;
    background-color: #000;
    border: 1px solid #000;
  }

  &:focus {
    color: #ffffff;
    background-color: #000080;
    border: 1px solid #000080;
    box-shadow: none;
  }

  &.light {
    color: #000080;
    background-color: #ffffff;
    border: 1px solid #ffffff;
  }

  &.color-secondary {
    color: #ffffff;
    background-color: #134a7c;
    border: 1px solid #134a7c;

    &:hover {
      color: #ffffff;
      background-color: #13436f;
      border: 1px solid #13436f;
    }
  }

  &.grey-btn {
    color: #495057;
    background-color: #c1ccd3;
    border: 1px solid #c1ccd3;
    -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0));
    filter: none;
    box-shadow: none;

    &:hover {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
    }
  }

  &.iInKaI {
    color: #ffffff;
    background-color: #000080;
    border: 1px solid #000080;
    font-size: 15px;

    &:hover {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
      box-shadow: none !important;
      outline: none;
    }

    &:focus {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
      box-shadow: none !important;
      outline: none;
    }

    &.focus-visible:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
    }
  }

  &.start-meeting-btn {
    color: #ffffff;
    background-color: #000080;
    border: 1px solid #000080;
    font-size: 15px;

    &:hover {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
      box-shadow: none !important;
      outline: none;
    }

    &:focus {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
      box-shadow: none !important;
      outline: none;
    }

    &.focus-visible:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
    }
  }

  &.btn-exit {
    color: #ffffff;
    background-color: #dc3545;
    border: 1px solid #dc3545;
    font-size: 15px;

    &:hover {
      color: #ffffff;
      background-color: #c82333;
      border: 1px solid #bd2130;
      box-shadow: none !important;
      outline: none;
    }

    &:focus {
      color: #ffffff;
      background-color: #c82333;
      border: 1px solid #bd2130;
      box-shadow: none !important;
      outline: none;
    }

    &.focus-visible:focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
    }
  }

  &.outline {
    color: #000080;
    background-color: transparent;
    border: 1px solid #000080;
    font-size: 15px;

    &:hover {
      color: #000080;
      background-color: #dbe3eb;
      border: 1px solid #000080;
      box-shadow: none;
      outline: none;
      filter: none;
    }

    &:focus {
      box-shadow: none !important;
      outline: none;
    }
  }

  &.outline-white {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #ffffff;

    &:hover {
      color: #ffffff;
      background-color: hsla(0, 0%, 100%, 0.2);
      border: 1px solid #ffffff;
    }
  }

  &.small-btn {
    font-size: 13px;
    min-width: initial;
    height: 30px;
    padding: 3px 10px 0px 10px;
  }

  &.small-btn-2 {
    font-size: 11px;
    min-width: initial;
    height: 24px;
    padding: 1px 10px 0px 10px;
  }

  &.small-btn-width {
    font-size: 13px;
    height: 30px;
    padding: 2px 20px 3px 20px;
  }

  &.red-btn {
    border: #eb335b;
    background-color: #eb335b;
  }

  &.ant-btn[disabled] {
    filter: none;
  }

  &.medium-btn {
    font-size: 13px;
    padding: 5px 10px 0px 10px;
    font-weight: 400 !important;
    width: initial;
    min-width: 100px;
    height: 35px;

    &:hover {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
      box-shadow: none;
      outline: none;
    }

    &:focus {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
    }

    &.fw-bold {
      font-weight: 700 !important;
    }
  }

  &.green-btn {
    color: #ffffff;
    background-color: #28a745;
    border: 1px solid #28a745;
    -webkit-filter: none;
    filter: none;
    box-shadow: none;

    &:hover {
      color: #ffffff;
      background-color: #208d38;
      border: 1px solid #208d38;
      -webkit-filter: none;
      filter: none;
    }

    &:focus {
      color: #ffffff;
      background-color: #208d38;
      border: 1px solid #208d38;
      -webkit-filter: none;
      filter: none;
    }
  }

  &.create-event-btn {

    // word-break: keep-all;
    .nav-text {
      // word-break: keep-all;
      min-width: 90px;
    }
  }

  &.fw-normal {
    font-weight: 400;
  }

  &.link-btn {
    padding: 10.4px 25px 0px 25px;

    &:hover {
      filter: none;
    }
  }

  &.w-initial {
    min-width: initial !important;
  }

  &.w-inherit {
    width: inherit !important;
  }

  &.h-38 {
    height: 38px;
  }

  &.h-40 {
    height: 40px;
  }

  &.padb-3 {
    padding-bottom: 3px !important;
  }

  &.txt-btn-pad {
    padding: 8px 10px 0px 10px;
  }

  &.red-btn2 {
    border: 1px solid #eb335b;
    background-color: #eb335b;

    &:hover {
      border: 1px solid #c91d42;
      background-color: #c91d42;
    }

    &:focus {
      border: 1px solid #c91d42;
      background-color: #c91d42;
    }
  }

  &.min-w-100 {
    min-width: 100px !important;
  }
}

.default-btn.disabled,
.default-btn:disabled {
  color: #fff;
  background-color: #000080;
  border-color: #000080;
}

.default-btn.disable-grey.disabled,
.default-btn.disable-grey:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #d9d9d9;
  border-color: #d9d9d9;
}

.outline-filter {
  padding: 6.4px 25px !important;
  height: 40px !important;
  max-width: 100% !important;
  text-shadow: none;

  &.min-w-100 {
    min-width: 100px !important;
  }
}

.ant-btn-primary:active {
  color: #fff;
  border-color: #000080;
  background: #000080;
}

.fltr-hpr {
  padding: 6.4px 25px !important;
  height: 40px !important;
  max-width: 100% !important;
  min-width: 50% !important;
  text-shadow: none;

  &:hover {
    color: #ffffff;
    background-color: #798892;
    border: 1px solid #798892;
    box-shadow: none;
    outline: none;
  }

  &:focus {
    color: #ffffff;
    background-color: #798892;
    border: 1px solid #798892;
  }

  &.pad-small {
    padding: 6.4px 15px !important;
  }
}

.fltr-hpr:disabled {
  background-color: #798892;
  border: 1px solid #798892;

  &:hover {
    color: #ffffff;
    background-color: #798892;
    border: 1px solid #798892;
  }

  &:focus {
    color: #ffffff;
    background-color: #798892;
    border: 1px solid #798892;
  }
}

.action-icons {
  color: #000080;
  background-color: transparent;
}

.w-20-desktop-main {
  .default-btn {
    &.outline {
      &:hover {
        color: #000080 !important;
        background-color: transparent;
        border: 1px solid #000080;
        box-shadow: none;
        outline: none;
      }

      &:focus {
        color: #000080 !important;
        background-color: transparent;
        border: 1px solid #000080;
      }
    }
  }
}

.connection-status-badge {
  // position: absolute;
  // top: 0;
  // right: 15px;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 9pt;
  font-weight: 600;
  font-family: "SegoeUIBold";
  margin-left: 25px;
}

.green-status {
  background: green;
}

.red-status {
  background: red;
}

.event-user-status-badge {
  // position: absolute;
  // top: 0;
  // right: 15px;
  color: #000;
  padding: 0px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  font-family: "SegoeUIBold";
  margin-left: 15px;
}

.user-green-status {
  color: green;
}

.user-red-status {
  color: red;
}

.block-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  text-indent: -9999px;
}

.mbpx-5 {
  margin-bottom: 5px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mb-60 {
  margin-bottom: 60px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.hd1 {
  border-bottom: 1px solid #ccc;
  color: #252525;
  font-size: 30px;
  font-weight: 800 !important;
  line-height: 36px;
  padding: 0px 30px 6px 0px;
  margin-bottom: 30px;
  width: 100%;
}

.hd2 {
  color: #252525;
  font-size: 25px;
  font-weight: 800 !important;
  line-height: 2rem;
  margin-bottom: 10px;
  padding: 10px 0 10px;
}

.hd3 {
  color: #252525;
  font-size: 22px;
  font-weight: 800 !important;
  line-height: 2rem;
  margin-bottom: 10px;
  padding: 10px 0 10px;
}

.hd4 {
  color: #252525;
  font-size: 18px;
  font-weight: 800 !important;
  line-height: 2rem;
  margin-bottom: 10px;
  padding: 10px 0 10px;
}

.hd5 {
  color: #252525;
  font-size: 16px;
  font-weight: 800 !important;
  line-height: 2rem;
  margin-bottom: 10px;
  padding: 10px 0 10px;
}

.form-close-modal {
  .modal-content {
    .modal-header {
      padding: 0;
      border: none;

      .close {
        margin: 0;
        position: absolute;
        right: 5px;
        top: 0;
        z-index: 9;
        font-size: 2rem;
        padding: 5px;
        opacity: 1;
      }
    }
  }
}

.form-close-modal {
  .modal-content {
    border-radius: 8px;

    .modal-header {
      padding: 0;
      border: none;

      .close {
        margin: 0;
        position: absolute;
        right: 5px;
        top: 0;
        z-index: 9;
        font-size: 2rem;
        padding: 5px;
        opacity: 1;
      }
    }
  }
}

.color-light {
  color: grey !important;
}

.generic_container_main {
  background-color: #ffffff;
  padding: 25px;
  border-radius: 5px;
  // border: 1px solid #ededed;
  -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #000;
}

textarea.ant-input {
  border-color: #000;
}

.ant-input {
  border-color: #000;
}

.ant-input:hover {
  border-color: #000;
}

.ant-picker,
.ant-picker:hover,
.ant-picker-focused {
  border: 1px solid #000;
}

.ant-input-affix-wrapper {
  border: 1px solid #000;

  &:hover {
    border: 1px solid #000;
  }
}

.field-style2 .ant-form-item-control-input-content .css-1s2u09g-control,
.field-style2 .ant-form-item-control-input-content .css-1s2u09g-control:focus {
  border: 1px solid #000;
  border-radius: 0px;
}

.field-style2.search-field .css-1s2u09g-control,
.field-style2.search-field .css-1s2u09g-control:focus {
  border: 1px solid #000;
  border-radius: 0px;
}

.field-style2.search-field .css-1s2u09g-control #react-select-2-placeholder {
  color: rgba(0, 0, 0, 0.85);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #000;
}

.date-time-main {
  display: flex;
  justify-content: flex-start;
  gap: 15px;

  .datepicker .ant-picker {
    width: 200px;
  }

  .timepicker .ant-picker {
    width: 150px;
    background-color: #f9f9f9;
  }
}

.form-styling {
  .ant-select {
    .ant-select-selector {
      border: 1px solid #000;

      &:hover {
        border: 1px solid #000;
      }

      &:focus {
        border: 1px solid #000;
      }
    }
  }
}

.generic-style {
  // height: 100vh;
  height: 100%;

  .ant-select {
    .ant-select-selector {
      border: 1px solid #000;

      &:hover {
        border: 1px solid #000;
      }

      &:focus {
        border: 1px solid #000;
      }
    }
  }

  .ant-input-number {
    border: 1px solid #000;
    height: 45px !important;

    &:hover {
      border: 1px solid #000;
    }

    &:focus {
      border: 1px solid #000;
    }
  }

  .ant-layout {
    background-color: #f9f9f9;
    // height: 100vh;
    height: 100%;
    margin-left: 250px;
  }

  .ant-layout-sider {
    background-color: #ffffff;
    border-right: 1px solid #e1e1e1;
    height: 100vh;

    .ant-layout-sider-children {
      padding: 15px 0;
      display: flex;
      flex-direction: column;
    }

    .ant-menu-inline,
    .ant-menu-vertical {
      border-right: 0px;

      .ant-menu-item {
        background-color: #ffffff;
        padding-left: 15px !important;
        // border-radius: 3px;
        width: calc(100% + 0px);
        margin: 0;

        &.ant-menu-item-selected {
          background-color: #f5f5f5;

          a {
            // font-family: "SegoeUIBold";
            font-weight: 700 !important;
          }
        }

        &::after {
          display: none;
        }

        a {
          display: flex;
          align-items: center;
          color: #000080;
          font-size: 14px;
          font-family: "Nunito Sans", sans-serif !important;

          &:hover {
            color: #000080;
          }

          .nav-text {
            margin-left: 15px;

            &.left-space {
              margin-left: 14px;
            }
          }

          .anticon-home {
            top: -1px;
            position: relative;
          }

          .anticon-signal {
            top: -1px;
            position: relative;
          }

          >.fa-user-tie {
            margin-right: 2px;
          }

          >.fa-user-pen {
            margin-right: 2px;

            +.nav-text {
              margin-left: 10px;
            }
          }
        }

        &.beta-link {
          margin-top: 120px;
          margin-bottom: 20px;
        }
      }
    }
  }

  .sidebar-main {
    flex: 0 0 250px !important;
    max-width: 250px !important;
    min-width: 250px !important;
    width: 250px !important;
    // overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    overflow-y: auto;
    overflow-x: hidden;

    .logo-area {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 0 38px 15px;

      .logo {
        width: 110px;
        margin: 0;
      }

      .menu-close {
        font-size: 25px;
        color: black;
        padding-right: 15px;

        a {
          color: black;

          &:hover {
            color: black;
          }
        }
      }
    }

    .wordspace-hd {
      font-size: 13px;
      color: grey;
      margin-left: 15px;
    }

    .workspace-menu {
      position: relative;
      margin-bottom: 25px;
      background-color: #ffffff;
      border-radius: 7px;
      padding: 7px 7px 7px 15px;

      &:hover {
        // background-color: #f5f5f5;
        // cursor: pointer;
      }

      &:focus {
        background-color: #f5f5f5;
      }

      .store-menu {
        display: flex;
        align-items: center;
        gap: 15px;
      }

      .workspace-icon {
        width: 32px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        background-color: #000080;
        border-radius: 3px;

        svg {
          color: white;
          font-size: 17px;
        }
      }

      .store-name-txt {
        font-size: 1rem;
        color: #000080;
      }

      .store-owner-txt {
        font-size: 12px;
        color: grey;
      }

      .workspace-drop-box {
        position: absolute;
        z-index: 9;
        background: #fff;
        // left: calc(100% + 15px);
        left: calc(100% - 10px);
        top: calc(100% - 105%);
        // top: calc(100% - 150%);
        border-radius: 7px;
        -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
        width: 220px;
        padding: 6px 0;

        .drop-item {
          padding: 10px 16px;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0;
          text-align: left;
          color: #162b4c;
          display: block;

          &:hover {
            background: #f5f5f5;
          }

          .drop-icon {
            margin-right: 15px;
          }

          .drop-txt {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0;
            text-align: left;
          }
        }
      }
    }

    .event-btn {
      font-family: Nunito Sans, sans-serif !important;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 30px 0;
      border-radius: 3px;
      color: #ffffff !important;
      background-color: #000080 !important;
      border: 1px solid #000080 !important;
      height: 45px !important;
      -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      padding: 0 16px !important;

      &:hover {
        background-color: #000 !important;
        border: 1px solid #000 !important;
      }

      .nav-text {
        margin-left: 8px;
      }

      a {
        color: #ffffff !important;
        font-family: Nunito Sans, sans-serif !important;
        justify-content: center;
      }
    }

    .multi-btn {
      font-family: Nunito Sans, sans-serif !important;
      font-size: 16px;
      font-weight: 700;
      margin: 0 0 10px 0;
      border-radius: 3px;
      color: #ffffff !important;
      background-color: #000080 !important;
      border: 1px solid #000080 !important;
      height: 45px !important;
      -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      padding: 0 16px !important;

      &:hover {
        background-color: #000 !important;
        border: 1px solid #000 !important;
      }

      .nav-text {
        margin-left: 8px;
      }

      a {
        color: #ffffff !important;
        font-family: Nunito Sans, sans-serif !important;
        justify-content: center;
      }

      &.space-top {
        margin-top: 100px;
      }
    }
  }

  .submenu-wrapper {
    position: absolute;
    left: -1px;
    bottom: 60px;
    width: 100%;
  }

  .submenu-main .ant-menu-submenu-title {
    font-size: 14px;
    padding-left: 15px !important;

    .ant-menu-title-content {
      .svg-inline--fa {
        margin-right: 12px;
      }

      .menu-icon-direct {
        margin-right: 14px;
        color: #000080;

        &.fa-at {
          margin-right: 17px;
        }

      }

      .fa-gears {
        color: #000080;
      }
    }
  }

  .submenu-main .ant-menu-submenu-title:hover {
    color: #000080;
    background: whitesmoke;
  }

  .submenu-main.ant-menu-submenu-selected,
  .submenu-main.ant-menu-submenu-active {
    color: #000080;
  }

  .submenu-main.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
    color: #000080;
  }

  .ant-menu-submenu-inline.ant-menu-submenu-open {
    color: #000080;
    background: whitesmoke;
  }

  .submenu-main .ant-menu-sub.ant-menu-inline {
    background: #f9fafe;
    // padding: 15px 15px;
    // padding: 0 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .submenu-main .ant-menu-inline .ant-menu-item {
    background-color: transparent;
    padding: 0 0 0 35px !important;
    height: 35px;
    line-height: 35px;
  }

  .submenu-main .ant-menu-inline .ant-menu-item a {
    font-size: 14px;
  }

  .submenu-main .ant-menu-inline .ant-menu-item.ant-menu-item-selected {
    background-color: transparent;
    // background-color: #f1f1f1;
  }

  .ant-tooltip-inner {
    a {
      span.anticon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .site-layout-background {
    background: #fff;
  }

  .ant-layout-content {
    &.site-layout-background {
      background: #fff;
      margin: 2rem;
      padding: 24px;
      min-height: 280px;
    }
  }

  .ant-layout-header {
    padding: 0 1rem 0 2rem;

    .trigger {
      padding: 0 15px 0 0px;
      font-size: 18px;
      line-height: 58px;
      cursor: pointer;
      transition: color 0.3s;
      color: #ffffff;

      &:hover {
        color: #ffffff;
      }
    }

    &.site-layout-background {
      background-color: #000080;
      color: #ffffff;
      height: 55px;
      line-height: 55px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .logo {
      width: 110px;
      margin: 0;

      img {
        width: 100%;
      }
    }
  }
}

.header-main {
  .header-title {
    font-size: 1.5rem;
    // font-weight: 800!important;
  }

  .logout-btn {
    font-family: Nunito Sans, sans-serif !important;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    border-radius: 3px;
    color: #000080;
    background-color: #ffffff;
    border: 1px solid #ffffff;
    min-width: 90px;
    align-items: center;
    filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
    display: flex;

    svg {
      position: relative;
      top: 0;
      transform: rotate(180deg);
      font-size: 13px;
    }

    svg+span {
      margin-left: 8px;
    }

    &:hover {
      color: #000080;
      background-color: #ffffff;
      border: 1px solid #ffffff;
    }

    &:focus {
      color: #000080;
      background-color: #ffffff;
      border: 1px solid #ffffff;
    }
  }
}

.dashboard-content-container {
  width: 100%;
  // display: flex;
  // justify-content: center;
  height: calc(100vh - 55px);

  .dashboard-content-full {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;

    .dashboard-inner-content {
      padding: 2rem;
      width: 100%;
      // max-width: 1250px;
      align-self: center;

      &.recorded-room-width {
        // max-width: 1300px;
      }
    }

    .footer-main {
      border-top: 1px solid #e9ecef;
      padding: 12px 1.5rem 12px 1.5rem;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .footer-nav {
        list-style-type: none;
        margin: 0 0 0 0;
        padding: 0;
        display: inline-block;

        li {
          float: left;
          padding: 0 12px;
          position: relative;

          a {
            font-size: 14px;
            font-weight: 400;
            color: grey;
            text-decoration: underline;

            &:hover {
              color: grey;
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}

.grid-listing-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;

  .select-style {
    width: 190px;
  }

  .grid-listing-left {
    .hd2 {
      margin-bottom: 0;
    }
  }

  .grid-listing-right {
    display: flex;
    justify-content: space-around;
    gap: 15px;

    .grid-icon {
      position: relative;
      padding: 12px 15px 12px 15px;
      // width: 32px;
      // height: 32px;
      // line-height: 32px;
      text-align: center;
      border-radius: 4px;
      width: 45px;

      svg {
        color: #000;
        font-size: 17px;
      }

      &.active {
        background-color: #ffffff;
        -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
        filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      }
    }
  }
}

.sticky-header-bg {
  position: fixed;
  display: none;
  right: 0;
  left: 0;
  top: 0;
  z-index: -1;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);
  height: 76px;
  background: var(--white);
}

.ant-affix {
  .sticky-header-bg {
    display: block;
    width: auto;
  }
}

.form-styling {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #000080;
    border-color: #000080;
  }

  .ant-switch-checked {
    background: #020c3f;
  }

  .ant-form-item {
    margin-bottom: 20px;
  }

  .ant-form-item-label {
    padding-bottom: 5px;

    label {
      font-family: Nunito Sans, sans-serif !important;
      font-size: 15px;
      font-weight: 600;
    }
  }

  textarea {
    padding: 10px 10px;
  }

  .dark-field {
    textarea {
      // background-color: #f9f9f9;
      background-color: #ffffff;
    }

    .ant-select-selector {
      // background-color: #f9f9f9;
      background-color: #ffffff;
    }

    &.ant-input-affix-wrapper {
      // background-color: #f9f9f9;
      background-color: #ffffff;
    }

    input {
      // background-color: #f9f9f9;
      background-color: #ffffff;
    }
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: #000080;
    // font-size: 48px;
  }

  .ant-upload.ant-upload-drag:hover {
    border: 1px dashed #000080;
  }
}

.generic-style .css-1pahdxg-control,
.generic-style .css-1pahdxg-control:hover {
  border-color: #000080;
}

.modal-generic {
  .ant-modal-body {
    padding: 15px;
  }

  .ant-modal-content {
    border-radius: 7px;
  }

  .ant-modal-close-x {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
    color: black;
    line-height: 40px;
  }

  .modal-hd1 {
    color: #252525;
    font-size: 20px;
    margin-bottom: 15px;
    padding: 0px 0 8px;
    border-bottom: 1px solid #ccc;
  }

  .ant-modal-body h5.rounded {
    font-size: 15px;
  }

  .ant-tabs-tab {
    padding: 8px 24px;

    &.ant-tabs-tab-active {
      background-color: #f3f3f3;

      .ant-tabs-tab-btn {
        color: #000080;
      }
    }
  }

  .ant-modal-footer {
    .ant-btn-primary {
      font-family: Nunito Sans, sans-serif !important;
      font-size: 15px;
      font-weight: 700;
      margin: 0 5px 0;
      border-radius: 3px;
      color: #ffffff;
      background-color: #000080;
      border: 1px solid #000080;
      min-width: 120px;
      height: 45px;
      padding: 6.4px 25px 5.4px 25px;
      align-items: center;
      -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
    }

    .ant-btn-default {
      font-family: Nunito Sans, sans-serif !important;
      font-size: 15px;
      font-weight: 700;
      margin: 0 5px 0;
      border-radius: 3px;
      color: #ffffff;
      background-color: #000080;
      border: 1px solid #000080;
      min-width: 120px;
      height: 45px;
      padding: 6.4px 25px 5.4px 25px;
      align-items: center;
      -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));

      &.ant-btn {
        color: #000080;
        background-color: transparent;
        border: 1px solid #000080;

        &:hover {
          color: #000080;
          background-color: #dbe3eb;
          border: 1px solid #000080;
        }
      }
    }
  }
}

.min-modal-600 {
  .ant-modal {
    min-width: 600px;
  }
}

.modal-474 {
  width: 474px !important;
}

.modal-500 {
  min-width: 500px;
}

.modal-600 {
  min-width: 600px;
}

.modal-700 {
  min-width: 700px;
}

.modal-800 {
  min-width: 800px;
}

.modal-900 {
  min-width: 900px;
}

.modal-1000 {
  min-width: 1000px;
}

.cancel-event-modal {
  .anticon-exclamation-circle {
    margin: 5px 7px 0 0;
  }

  .ant-modal-confirm-title {
    font-family: Nunito Sans, sans-serif !important;
    color: #faad14;
    font-size: 25px;
    font-weight: 800 !important;
    margin-bottom: 0;
    padding: 0;
  }

  .ant-modal-confirm-content {
    line-height: 24px;
    color: #0f0b1f;
    font-size: 15px;
    margin-bottom: 40px;
    margin-left: 35px;
  }

  .ant-btn-default {
    color: #7a7a7a;
    font-size: 16px;
    display: inline-block;
    align-items: center;
    border: 0;
    box-shadow: none;
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    font-family: Nunito Sans, sans-serif !important;
    font-weight: 600 !important;

    span {
      text-decoration: underline;
    }

    &.ant-btn-dangerous {
      font-family: Nunito Sans, sans-serif !important;
      font-size: 15px;
      font-weight: 600 !important;
      margin: 0 5px 0;
      border-radius: 3px;
      color: #ac000d;
      background-color: transparent;
      border: 1px solid #ac000d;
      min-width: 120px;
      height: 45px;
      padding: 6.4px 25px 6px 25px;
      align-items: center;
      -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));

      span {
        text-decoration: none;
      }

      &:focus {
        color: #ac000d;
        background-color: transparent;
        border: 1px solid #ac000d;
      }
    }
  }

  .ant-btn-dangerous {
    font-family: Nunito Sans, sans-serif !important;
    font-size: 15px;
    font-weight: 700;
    margin: 0 5px 0;
    border-radius: 3px;
    color: #eb335b;
    background-color: transparent;
    border: 1px solid #eb335b;
    min-width: 120px;
    height: 45px;
    padding: 6.4px 25px;
    align-items: center;
    -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
  }

  .ant-btn-success {
    font-family: Nunito Sans, sans-serif !important;
    font-size: 15px;
    font-weight: 700;
    margin: 0 5px 0;
    border-radius: 3px;
    color: #07bc0c;
    background-color: transparent;
    border: 1px solid #07bc0c;
    min-width: 120px;
    height: 45px;
    padding: 6.4px 25px;
    align-items: center;
    -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
  }
}

.custom-paginate {
  text-align: right;
  justify-content: flex-end;
  margin: 15px 0 15px;

  .page-item {
    margin: 0 5px;

    a.page-link {
      padding: 0.5rem 1rem;
      font-weight: 700;
      border-radius: 0.25rem;
      color: #000080;

      &:focus {
        box-shadow: none !important;
        border-color: transparent !important;
        background-color: white;
        color: black;
      }
    }

    &:last-child {
      margin-right: 0px;
    }

    &.disabled {
      opacity: 0.8;

      a.page-link {
        color: #6c757d !important;
      }
    }
  }
}

.no-result-found {
  text-align: center;
  margin: auto;
  padding: 2rem 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.event-ended {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .no-result-img {
    margin: 0 0 25px 0;
    background-color: #ffffff;
    border: 1px solid #ebebeb;
    overflow: hidden;
    border-radius: 100px;
    width: 100px;
    height: 100px;

    img {
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }

  .no-event-hd {
    margin: 0 0 15px 0;
  }

  p {
    color: #000000;
    font-size: 16px;
  }
}

.mar-top-15 {
  margin-top: 15%;
}

.mar-top-10 {
  margin-top: 10%;
}

.loader-center {
  text-align: center;
  margin: auto;
  padding: 2rem 1rem;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .ant-spin {
    color: #000080;

    .ant-spin-dot-item {
      background-color: #000080;
    }
  }
}

.spin-color {
  .ant-spin {
    color: #000080;

    .ant-spin-dot-item {
      background-color: #000080;
    }
  }
}

.spin-color-white {
  .ant-spin {
    color: #ffffff;

    .ant-spin-dot-item {
      background-color: #ffffff;
    }
  }
}

.pos-top-3 {
  top: 3px;
}

.tabs-vertical {
  .ant-tabs-tab-btn {
    svg {
      margin-right: 10px;
      color: grey;
    }
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      svg {
        color: #000080;
      }
    }
  }
}

// .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
//   color: #000080;
// }

.ant-tabs-ink-bar {
  background: #000080 !important;
}

.ant-tabs-tab:hover {
  color: #000080;
}

.modal-form-style {
  .ant-form-item-label {
    line-height: 1;

    label {
      font-size: 14px;
      font-weight: 700 !important;
      color: #000000;
      font-family: Nunito Sans, sans-serif !important;
      height: inherit;
    }
  }

  .checkbox-block {
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .ant-checkbox-wrapper-in-form-item {
            margin-left: 0px;
          }
        }
      }
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000080;
  border-color: #000080;
}

.full-height {
  height: 100vh;
  display: flex;
}

.events-tabs-main {
  .ant-tabs-nav {
    margin-bottom: 0px;

    .ant-tabs-nav-wrap {
      padding-bottom: 10px;

      .ant-tabs-ink-bar {
        height: 100%;
        z-index: 0;
        border-radius: 2rem;
      }
    }
  }

  .ant-tabs-tab {
    padding: 0.5rem 1.5rem;
    margin: 0 10px 0 0px;
    min-width: 80px;
    justify-content: center;
    font-weight: 600 !important;
    font-family: Nunito Sans, sans-serif !important;
    font-size: 16px;

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: white;
        z-index: 10;
      }
    }
  }
}

.upload-image-main {
  &.ant-upload.ant-upload-drag {
    width: 353px;
    height: 197px;
    background-color: #ffffff;
    border-radius: 7px;

    p {
      color: #a9a19c;
      transition: color 0.3s;
    }

    &:hover {
      p {
        color: #000080;
      }
    }
  }

  +.ant-upload-list-picture {
    .ant-upload-list-item-list-type-picture {
      max-width: 353px;

      .anticon-delete {
        color: #ff4d4f;
      }
    }
  }
}

.preview-image-main {
  margin-bottom: 14px !important;

  .ant-image {
    // width: 360px;
    // width: 354px;
    // height: 200px;
    width: 353px;
    height: 197px;
    overflow: hidden;
    background-color: #ffffff;
    // background-color: #000080;
    background: #00008a;
    background: -webkit-gradient(left top,
        left bottom,
        color-stop(0, #00008a),
        color-stop(100%, #000c40));
    background: linear-gradient(180deg, #00008a 0, #000c40);
    border: 1px dashed #d9d9d9;
    border-radius: 7px;
    text-align: center;

    .ant-image-img {
      width: initial;
      max-width: 100%;
      max-height: 100%;
    }
  }

  .ant-image-mask-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.ant-image-preview-img-wrapper {
  max-width: 50%;
  max-height: 50%;
  margin: auto;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
  border-color: #000080;
}

.ant-radio-checked .ant-radio-inner {
  border-color: #000080;
}

.ant-radio-inner::after {
  background-color: #000080;
}

.ant-radio-checked::after {
  border: 1px solid #000080;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus+.ant-radio-inner {
  border-color: #000080;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-imput) .ant-select-selector {
  border-color: #000080;
  // box-shadow: 0 0 0 2px rgb(1,11,64 / 20%);
}

.permission-options {
  .ant-space-vertical {
    gap: 15px !important;
  }

  .ant-radio-wrapper {
    align-items: flex-start;
  }

  .permission-txt {
    font-size: 13px;
    color: grey;
  }

  h6 {
    font-weight: 700 !important;
    margin-bottom: 0;
  }

  .ant-radio-wrapper {}
}

.users-detail-table {
  border: 1px solid #cccccc;

  thead {
    background-color: #000080;
    color: #ffffff;

    th {
      padding: 0.75rem;

      h5 {
        color: #ffffff;
        margin-bottom: 0;
      }
    }
  }

  tbody {
    tr {
      background-color: #f9f9f9;
      font-size: 16px;

      td {
        padding: 0.5rem 0.75rem;
        vertical-align: middle;
        background-color: white;

        h5 {
          color: #000080;
          margin-bottom: 0;
        }

        h6 {
          color: #64748b;
          margin-bottom: 0;
        }

        .status {
          // text-align: center;

          &.active {
            color: #23a940;
          }

          &.notactive {
            color: red;
          }
        }

        .this-is-you {
          font-size: 12px;
          text-transform: uppercase;
          text-align: center;
          color: #64748b;
          font-weight: 700 !important;
          background-color: #e7eaee;
          border-radius: 99px;
          padding: 5px 12px;
          display: inline-block;
        }
      }
    }
  }
}

// .table-fixed {
.table-fixed-new {
  font-family: Nunito Sans, sans-serif !important;
  width: 100%;
  background-color: #ffffff;
  border-top: 0;
  border-left: 0;
  border-right: 0;

  tbody {
    max-height: 262px;
    overflow-y: auto;
    width: 100%;
  }

  thead,
  tbody,
  tr,
  td,
  th {
    display: block;
  }

  tbody {
    tr {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    td {
      float: left;
      padding: 0.5rem;
      display: flex;
      align-items: center;
    }
  }

  thead {
    tr {
      th {
        padding: 0.5rem;
        font-size: 16px;
        font-weight: 400 !important;
        float: left;
        background-color: #000080;
        border-color: #dee2e6;
        color: white;
        border-bottom-width: 0;

        .prod-img-width {
          width: 50px;
        }
      }
    }
  }

  .listing-image {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 auto;
    border: 1px solid #efefef;

    img {
      // object-fit: cover;
      // max-width: 100%;
      width: 100%;
      object-position: top;
    }
  }

  td:not(:last-child) {
    border-right-width: 0;
  }

  tr:not(:last-child) td {
    border-bottom-width: 0;
  }

  th:not(:last-child) {
    border-right-width: 0;
  }

  tr:not(:last-child) th {
    border-bottom-width: 0;
  }
}

.listing-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
  // margin: 0 auto;
  border: 1px solid #efefef;

  img {
    // object-fit: cover;
    // max-width: 100%;
    width: 100%;
    object-position: top;

    &.full-cover {
      height: 100%;
      object-fit: cover;
    }
  }

  &.default-listing-image {
    background-color: #000080;
    line-height: 45px;
    text-align: center;

    img {
      max-width: 100%;
      max-height: 100%;
      width: initial;
      object-position: top;

      &.full-cover {
        height: initial;
      }
    }
  }
}

.product-listing-slider {
  font-family: Nunito Sans, sans-serif !important;

  .table-header {
    tr {
      background-color: #000080;

      th {
        color: white;
        font-size: 16px;
        font-weight: 400 !important;
        padding: 0.5rem;
        float: left;

        &.image {
          width: 100px;
        }
      }
    }
  }

  tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;

    tr {
      td {
        vertical-align: middle;
        padding: 0.5rem;
        float: left;
      }
    }
  }
}

.border-hd {
  border-bottom: 1px solid lightgrey;
  padding-bottom: 7px;
  padding-top: 10px;
  font-weight: 700 !important;
  margin-bottom: 1rem;
}

.form-new {
  margin-top: 20px;
  margin-bottom: 20px;

  &.form-styling {
    .ant-form-item-label {
      padding-bottom: 0px;
    }

    .ant-form-item {
      margin-bottom: 10px;
    }
  }

  .preview-img {
    border-radius: 0;
    width: 100%;
    height: 162px;
    line-height: 158px;
    overflow: hidden;
    text-align: center;
    background-color: #000080;
    position: relative;
    // border: 1px solid #e1e1e1;

    img {
      max-width: 100%;
      max-height: 100%;
    }

    &.sample-image {
      background-color: #000080;
      margin-bottom: 20px;
      border-radius: 5px;

      img {
        // height: 100%;
      }
    }
  }

  .preview-date-main {
    margin: 0px 0;
    padding: 0 15px;

    .date-txt {
      font-size: 13px;
      margin-bottom: 5px;

      &.notes {
        margin-bottom: 10px;
      }
    }

    .preview-date {
      font-size: 14px;
      font-weight: 700 !important;
      margin-bottom: 5px;

      .fw-normal {
        font-weight: 400 !important;
      }
    }

    .preview-time {
      font-size: 14px;
      margin-bottom: 0px;

      &.notes-txt {
        min-height: 58px;
      }
    }

    .event-detail-txt {
      position: relative;
      font-size: 13px;
      margin: 10px 0 0 0;
      display: flex;
      align-items: center;
      gap: 10px;

      .anticon {
        color: grey;
      }
    }

    &.producer-style {
      .preview-date {
        display: flex;
        gap: 10px;

        svg {
          color: grey;
        }
      }
    }
  }
}

.your-copy-link {
  display: flex;
  justify-content: space-between;
  border: 1px solid #000;
}

.your-copy-link .item-a {
  display: inline-block;
  padding: 6px 10px;
  /* cursor: default; */
  /* overflow: hidden; */
  /* height: 28px; */
  word-break: break-all;
}

.your-copy-link .item-a a {
  color: #000;
}

.your-copy-link .item-b {
  padding: 0;
  border-left: 1px solid #000;
  cursor: pointer;
}

.your-copy-link .item-b button {
  height: 100%;
  border: 0px;
  border-radius: 0;
  cursor: pointer;
  background: #000080;
  color: white;

  &:hover {
    border: 0px;
    background: #000000;
    color: white;
  }
}

.your-copy-link .copied {
  color: #fff;
  background-color: #198754;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
  background: #000080;
  border-color: #000080;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role="button"]:hover .ant-steps-item-icon {
  border-color: #000080;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role="button"]:hover .ant-steps-item-icon .ant-steps-icon {
  color: #000080;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
  color: #000080;
}

.ant-steps-item-icon .ant-steps-icon {
  color: #000080;
}

.register-steps .ant-steps-item-finish .ant-steps-item-icon {
  border-color: #000080;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}

.register-steps .ant-steps-item-icon .ant-steps-icon {
  top: -2.2px;
}

.register-steps .ant-steps-item-title:after {
  background: #ededed;
  height: 3px;
  // top: 40%;
}

.media-box-post {
  position: relative;
}

.media-box-post a img.MuiCardMedia-root,
.media-box-post a video.MuiCardMedia-root {
  height: auto;
}

.media-box-post .btn-play {
  position: absolute;
  right: 16px;
  // z-index: 999;
  z-index: 10;
  color: white;
  width: 22px;
  height: 22px;
  text-align: center;
  background-color: #222;
  border-radius: 6px;
}

.media-box-post .btn-carousel {
  // background: transparent;
}

.media-box-post .btn-carousel img {
  width: 100%;
  // filter: invert(100%);
  // -webkit-filter: invert(100%);
  // filter: brightness(0) invert(1);
  position: relative;
  // top: -5px;
  // width: 22px !important;
  //   right: 7px;

  width: 22px !important;
  right: 0px !important;
  top: -2px !important;
}

.media-box-post .btn-play .fa {
  font-size: 10px;
  position: relative;
  // top: -4px;
  // right: 1px;
  top: -2px;
  right: 0px;
}

.select-position {
  z-index: 9999;
}

.connection-status-topbar-badge-red {
  background: red !important;
  color: #fff !important;
  padding: 4px 10px;
  border-radius: 5px;
  position: relative;
  margin-left: 12px;
  height: 25px;
  line-height: 14px;
  // top: 10px;
}

.topbar-green {
  background: green !important;
}

.mobile-gallery::-webkit-scrollbar {
  width: 10px;
}

.mobile-gallery::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.mobile-gallery::-webkit-scrollbar-thumb,
.mobile-gallery::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.add-image-modal .modal-dialog {
  max-width: 50%;

  @media only screen and (max-width: 767px) {
    .min-w-initial-res {
      min-width: initial;
    }

    max-width: 80%;
    margin: 0.5rem auto;
  }
}

.add-image-modal .sku-image {
  max-width: 100%;
}

.add-image-modal .sku-image-box {
  display: flex;
  justify-content: center;
  // align-items: center;
  text-align: center;
}

.add-image-modal .image-space {
  position: relative;
  top: 26px;

  label {
    cursor: pointer;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.upload-file-box {
  height: 150px;
  width: 100%;
  border: 1px solid #c1c1c1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00008a;
  background: -webkit-gradient(left top,
      left bottom,
      color-stop(0, #00008a),
      color-stop(100%, #000c40));
  background: linear-gradient(180deg, #00008a, #000c40);

  .sku-image {
    max-height: 100%;
  }
}

.add-image-modal .modal-content {
  padding-bottom: 25px;
}

.sku-copy .your-copy-link .item-a {
  background-color: #e9ecef;
  word-break: break-all;
}

.sku-copy .your-copy-link .item-b {
  background: #000080;
  color: #fff;
}

/*****Update Form Url Copy******/
.url-copy {
  // display: flex;
}

.url-copy .your-copy-link {
  display: flex;
  justify-content: space-between;
  border: 1px solid #e1e1ee;
  height: 45px;
  line-height: 44px;
  background: #fff;
}

.url-copy .your-copy-link .item-a {
  display: inline-block;
  padding: 0px 10px;
  font-size: 14px;
  overflow: hidden;
}

.url-copy .your-copy-link .item-a a {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 400;
  font-size: 14px;
}

.url-copy .your-copy-link .item-b {
  padding: 0px 10px;
  cursor: pointer;
  border-left: 1px solid #e1e1e1;
}

.action-btn {
  top: -2px;
  margin-right: 4px;
  position: relative;
}

.action-btn .btn {
  padding: 0px 5px;
  margin-left: 4px;
  border: none;
}

.abc-checkbox {
  padding-left: 0;
  margin-bottom: 12px;
}

.acct-promo-sec {
  width: 100%;
  margin-top: 20px;

  h4 {
    font-size: 1.15rem;
  }

  .acct-promo-sec-inr {
    display: flex;

    .form-control {
      margin-right: 15px;
    }
  }

  .make-canc-pay {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
  }
}

.date-loader {
  display: inline-grid;
  float: none !important;
}

// .summary-loading {
//   height: 146px !important;
// }
.marketing-tabs .nav-link.disabled {
  pointer-events: auto;
  cursor: no-drop;
}

// .campaign-sum-loading {
//   height: 304px !important;
// }
.summary_height {
  // min-height: 445px !important;
}

.promo_discount {
  border: 1px solid #e1e1ee;
  display: flex;
  align-items: center;
  min-height: 45px !important;
}

.textarea-modal-scroll-style::-webkit-scrollbar {
  width: 10px;
}

.textarea-modal-scroll-style::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.textarea-modal-scroll-style::-webkit-scrollbar-thumb,
.textarea-modal-scroll-style::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.select-modal.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 45px !important;
  padding: 0 10px !important;
}

.select-modal.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 44px !important;
}

.select-modal.ant-select-single .ant-select-selector .ant-select-selection-item {
  line-height: 44px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px !important;
}

.store-select.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 38px !important;
}

.inner-image-box {
  position: relative;
}

.inner-image-box .close {
  position: absolute;
  // background: #000;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  right: 4px;
  top: 4px;
  text-align: center;
  cursor: pointer;
  opacity: 0.6;
}

.inner-image-box .close span {
  color: #000;
  font-size: 18px;
  position: relative;
  font-family: "SegoeUIBold";
  top: -6px;
  font-weight: 600;
  text-shadow: none !important;
}

.inner-image-box .image_num {
  position: absolute;
  background: #fff;
  width: 16px;
  height: 16px;
  text-align: center;
  bottom: 8px;
  color: #000;
  line-height: 15px;
  left: 8px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  font-family: "SegoeUIBold";
  cursor: pointer;
}

.inner-image-box .img1 {
  cursor: pointer;
}

.prod-link {
  font-family: "Nunito Sans", sans-serif !important;
  font-weight: 600 !important;
}

.prod-desc {
  text-align: right;
  max-width: 70%;
  line-height: 18px;
}

.tag-div-main {
  background-color: white;
  border-radius: 50%;
  color: #000;
  font-size: 11px;
  height: 20px;
  left: 45%;
  line-height: 20px;
  position: absolute;
  width: 20px;
  cursor: pointer;
  text-align: center;
  font-family: "SegoeUIBold";
}

.tag-area-main {
  // width: 100%;
  // height: 100%;
  // position: relative;
}

.source_cap {
  text-transform: capitalize;
}

.clear_circle {
  position: absolute;
  right: 4px;
  top: 4px;
  color: white;
  animation: expand 0.1s ease;
  cursor: pointer;
}

.img-error {
  color: #fd0000;
  position: relative;
  top: 36px;
}

.source_cap.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  color: initial;
}

@-webkit-keyframes expand {
  0% {
    top: 0px;
  }

  25% {
    top: 1px;
  }

  50% {
    top: 2px;
  }

  75% {
    top: 3px;
  }

  100% {
    top: 4px;
  }
}

.zindex-100 {
  z-index: 100;
}

@media (max-width: 1300px) and (min-width: 769px) {
  .image-edit-box .image-box {
    flex: 0 0 60% !important;
    max-width: 60% !important;
  }

  .image-edit-box .image-edit-links {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .image-edit-box .image-wrapper {
    display: block !important;
  }
}

.dash_icon-top {
  color: #e4e7ed;
  float: left;
  margin-top: 0;
}

.imp-t {
  font-weight: 600;
  font-size: 1.57142857rem;
  font-family: "Nunito Sans", sans-serif;
  line-height: 1.5;
  color: #575757;
}

.imp-tx {
  font-size: 0.928571429rem;
}

.coming_iner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: table;
  right: 0;
  text-align: center;
}

.min-w-200 {
  min-width: 200px;
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input[disabled]~.custom-control-label::after {
  opacity: 0.4;
  cursor: no-drop;
}

.custom-switch {
  top: -5px;
  // margin-right: 10px;
}

.custom-switch.custom-switch-md .custom-control-label {
  padding-left: 0.6rem;
  padding-bottom: 1.5rem;
  cursor: pointer;
}

.custom-switch.custom-switch-md .custom-control-label::before {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #000080;
  border-color: #000080;
}

.custom-switch.custom-switch-md .custom-control-label::after {
  width: calc(1.5rem - 4px);
  height: calc(1.5rem - 4px);
  border-radius: calc(2rem - (1.5rem / 2));
}

.custom-switch.custom-switch-md .custom-control-input:checked~.custom-control-label::after {
  transform: translateX(calc(1.5rem - 0.25rem));
}

.custom-paginate>.active>a {
  background-color: #000080;
  border-color: #000080;
  color: #fff;
}

.custom-paginate>li>a {
  border: 1px solid #000080;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.custom-paginate>.active>a,
.custom-paginate>.active>span,
.custom-paginate>.active>a:hover,
.custom-paginate>.active>span:hover,
.custom-paginate>.active>a:focus,
.custom-paginate>.active>span:focus {
  background-color: #fff !important;
  border: 1px solid #000080 !important;
  outline: none;
  color: #000080 !important;
}

.select-role-list {
  padding: 15px 15px;
}

.select-role-list .ant-radio-group {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  // flex-wrap: wrap;
}

.select-role-list .ant-radio-group-large .ant-radio-button-wrapper {
  height: 120px;
  font-size: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // line-height: 38px;
  color: #666666;
  // box-shadow: none;
  border-radius: 8px;
  box-shadow: 0 0 8px rgb(10 22 70 / 15%);
  border: none;
}

.select-role-list .ant-radio-group-large .ant-radio-button-wrapper:hover {
  color: #000080;
}

.select-role-list .ant-radio-group-large .ant-radio-button-wrapper .ant-radio-button+span {
  display: flex;
  flex-direction: column;
  line-height: initial;
  gap: 20px;
  font-weight: 400;
}

.select-role-list .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}

.select-role-list .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #000080;
  background: #ededed;
  border-color: #000080;
  box-shadow: 0 0 8px rgb(10 22 70 / 5%);
}

.select-role-list .select-check-icon {
  position: absolute;
  right: -7px;
  top: -7px;
  font-size: 20px;
  font-weight: 700;
  color: #000080;
  background-color: white;
  border-radius: 50%;
  display: none;
}

.select-role-list .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) .select-check-icon {
  display: block;
}

.select-role-list .ant-radio-group-large .ant-radio-button-wrapper .ant-radio-button+span .select-role-icon {
  font-size: 35px;
}

.select-role-list .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #000080;
}

.select-role-list .ant-radio-button-wrapper-checked:not([class*=" ant-radio-button-wrapper-disabled"]).ant-radio-button-wrapper:first-child {
  border-right-color: #000080;
}

.inventory-box-main {
  margin-bottom: 30px;
}

.inventory-box-main .ant-table table {
  width: 100%;
  border: 1px solid #dee2e6;
}

.inventory-box-main .ant-table-thead>tr>th {
  text-align: left;
}

.inventory-box-main .ant-table-thead .ant-table-cell {
  background: #000080;
  color: #fff;
  text-transform: none;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
}
.inventory-box-main .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell:hover {
  background: #02025a;
}

.inventory-box-main .ant-table-tbody>tr>td:last-child {
  text-align: left;
}

.inventory-box-main .ant-table-tbody>tr>td {
  border-right: 0px !important;
  padding: 0.5rem 0.75rem !important;
  border-bottom: 0px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.inventory-box-main tr.drop-over-upward td {
  border-top: 1px dashed #000080;
}

.inventory-box-main tr.drop-over-downward td {
  border-top: 1px dashed #000080;
}

.actions-list .anticon-delete {
  color: #dc3545;
  font-size: 16px;
}

.inventory-box-main .ant-table-cell-scrollbar:not([rowspan]) {
  box-shadow: none;
  border-left: 0px;
}

.inventory-box-main .ant-table-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}


.inventory-box-main .ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.inventory-box-main .ant-table-body::-webkit-scrollbar-thumb,
.inventory-box-main .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.inventory-box-main .ant-table-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.inventory-box-main .ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.inventory-box-main .ant-table-content::-webkit-scrollbar-thumb,
.inventory-box-main .ant-table-content::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.grayed {
  background-color: #b7b7b75c !important;
  cursor: not-allowed;
}


.default-btn.replacing {
  background-color: #b7b7b75c;
  transition: 1s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.product-listing-table-main .ant-table-body::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.product-listing-table-main .ant-table-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.product-listing-table-main .ant-table-body::-webkit-scrollbar-thumb,
.product-listing-table-main .ant-table-body::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.product-listing-table-main .ant-table-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

.product-listing-table-main .ant-table-content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.product-listing-table-main .ant-table-content::-webkit-scrollbar-thumb,
.product-listing-table-main .ant-table-content::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.inventory-box-main tr.ant-table-measure-row td {
  padding: 0px !important;
}

.product-listing-table-main tr.ant-table-measure-row td {
  padding: 0px !important;
}

.inventory-box-main .prod-sizing {
  margin-bottom: 8px;
}

.status-info {
  display: flex;
  // justify-content: center;
  align-items: center;
  gap: 7px;

  .status-text {
    font-size: 13px;
  }

  .status-flag {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: green;

    &.green {
      background-color: green;
    }

    &.red {
      background-color: #dc3545;
    }

    &.yellow {
      background-color: #fe8300;
    }
  }
}

.balance-icon {
  color: #000080;
  font-size: 15px;
}

.variants-main {
  display: flex;
  align-items: center;

  // gap: 25px;
  .variant {
    border-right: 1px solid #dee2e6;
    padding-right: 25px;
    margin-right: 25px;

    &:nth-child(2) {
      min-width: 225px;
      text-align: center;
    }
  }
}

.details-table .ant-table-container table>thead>tr:first-child th:first-child {
  // text-align: center;
}

.details-table .ant-table-container table>thead>tr:first-child th:nth-child(2) {
  // text-align: center;
}

.details-table .ant-table-tbody>tr td:nth-child(2) {
  font-weight: 700;
  // text-align: center;
}

.balance-area {
  display: flex;
  align-items: center;
  gap: 10px;

  .balance-count {
    min-width: 30px;
  }

  .balance-btn {
    height: 25px;
  }
}

.desc-limit {
  max-height: 68px;
  overflow: hidden;
}

.pos-index {
  position: relative;
  z-index: 100;
}

.ant-form .ant-form-item.pos-index {
  flex-wrap: initial;
}

.simulcasting-hd {
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;
}

.close-icon-modal-new {
  position: absolute;
  right: 15px;
}

.stream-box-white {
  background-color: #ffffff;
  padding: 25px 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 26%);
}

.simulcasting-modal-main {
  position: relative;
  min-height: 150px;
  padding-left: 150px;
}

.platform-icon-box-main {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 125px;
}

.platform-icon-box {
  width: 125px;
  height: 125px;
  border: 1px solid #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
}

.platform-hd {
  display: flex;
  align-items: center;
  gap: 20px;
}

.configuration-hd {
  font-family: Nunito Sans, sans-serif !important;
  font-weight: 700 !important;
  font-size: 16px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.platform-status-badge-green {
  // position: absolute;
  // right: 10px;
  background: green;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 10px;
  text-transform: uppercase;
  // font-weight: 600;
  // font-family: "SegoeUIBold";
}

.platform-status-badge-red {
  // position: absolute;
  // right: 10px;
  background: red;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  top: 11px;
  font-size: 10px;
  text-transform: uppercase;
  // font-weight: 600;
  // font-family: "SegoeUIBold";
}

.tabs-vertical-main {
  height: 100%;
  min-height: 525px;

  .ant-tabs-nav {
    min-width: 220px;

    .ant-tabs-tab {
      color: #000080;
      font-size: 16px;
      font-family: "Nunito Sans", sans-serif !important;
      border-bottom: 1px solid #dbdbdb;
      margin: 0 !important;
    }
  }
}

.gsw {
  width: 200px;
}

.tabs-vertical-main .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000080;
  font-weight: 700;
}

.tabs-vertical-main.ant-tabs-left>.ant-tabs-content-holder,
.ant-tabs-left>div>.ant-tabs-content-holder {
  margin-left: -3px;
  border-left: 3px solid #ededed;
}

.min-40 {
  min-width: 40px;
}

.min-50 {
  min-width: 50px;
}

.min-60 {
  min-width: 60px;
}

.min-70 {
  min-width: 70px;
}

.min-75 {
  min-width: 75px;
}

.min-80 {
  min-width: 80px;
}

.min-90 {
  min-width: 90px;
}

.min-100 {
  min-width: 100px;
}

.min-110 {
  min-width: 110px;
}

.min-120 {
  min-width: 120px;
}

.min-130 {
  min-width: 130px;
}

.min-140 {
  min-width: 140px;
}

.min-150 {
  min-width: 150px;
}

.min-300 {
  min-width: 300px;
}

.min-350 {
  min-width: 350px;
}

.data-listing-box {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #ede9e9;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 6px;
}

.data-listing-box:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.data-listing-box .count {
  font-size: 1rem;
  margin-bottom: 0.3em;
}

.data-listing-box .count-title {
  color: #798892;
  font-size: 1rem;
  margin-bottom: 0.3em;
}

.data-listing-box:last-child .count {
  margin-bottom: 0;
}

.data-listing-box:last-child .count-title {
  margin-bottom: 0;
}

.modal-header-style2 {
  padding: 1rem 1rem 0.5rem 1rem;

  .modal-title {
    font-size: 20px;
  }

  .close {
    font-size: 30px;
    padding: 5px 8px 0 0;
    opacity: 1;
  }
}

.default-btn.payment-btns {
  border-radius: 3px;

  .event-icon {
    font-size: 12px;

  }
}

.event-view-sale-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 5px;

  .event-views-sales-box {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 5px;
    font-size: 14px;

    .views-sales-icon {
      color: grey;
      font-size: 18px;
    }

    .views-sales-content {
      color: grey;
      font-size: 1.25rem;
      font-family: "Nunito Sans", sans-serif !important;
      font-weight: 600 !important;
      text-transform: uppercase;
    }
  }
}

.loading-wrap {
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.mb-5rem {
  margin-bottom: 0.5rem;
}

.generic-style .ant-layout-sider .ant-menu-inline .ant-menu-item a.unSelectedMenu {
  font-weight: 400 !important;
}

.form-style-new {
  .ant-input-number {
    border: 1px solid #000 !important;
  }
}

@media only screen and (min-width: 1600px) {
  .col-xl-6.stream-box-desktop-width {
    flex: 0 0 40%;
    max-width: 40%;
  }
}

@media only screen and (min-width: 1366px) {
  .w-20-desktop-main {
    .col-xl-3 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    .col-xl-5.fs-btn-desktop {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1365px) {
  .w-20-desktop-main {
    .col-xl-5.fs-btn-desktop {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .w-20-desktop-main.tags-form-space {
    .col-xl-3 {
      margin-bottom: 15px;
    }
  }
}

.modal-video-box-main {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  background-color: black;
  height: 250px;
  overflow: hidden;

  .modal-video {}
}

.status-flag-event-user {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 5px;

  &.green {
    background-color: green;
  }

  &.red {
    background-color: #dc3545;
  }

  &.yellow {
    background-color: #fe8300;
  }
}

@media only screen and (min-width: 1200px) {
  .menu-style-new {
    // height: 80%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .bottom-fixed-auto {
    margin-top: auto;
    // width: calc(100% + 40px);
    position: relative;
    // left: -20px;
  }

  .bottom-fixed-normal {
    // width: calc(100% + 40px);
    position: relative;
    // left: -20px;
  }

  .w-lg-50 {
    width: 50% !important;
  }
}

@media only screen and (max-width: 1900px) {
  .events-tabs-main {
    .ant-tabs-tab {
      padding: 0.5rem 1rem;
      min-width: 60px;
    }
  }
}

@media only screen and (max-width: 1366px) {
  .grid-listing-area .grid-listing-right .grid-icon {
    display: none;
  }
}

@media only screen and (max-width: 1350px) {
  .default-btn.medium-btn.fltr-hpr {
    padding: 6.4px 15px !important;
  }
}

@media only screen and (max-width: 1200px) {

  .post-analytics-tab .col-md-4,
  .post-analytics-tab .col-md-6 {
    margin-bottom: 15px;
  }

  .default-btn.medium-btn.fltr-hpr {
    width: initial !important;
    min-width: initial !important;
  }

  .generic-style {
    .sidebar-main {
      flex: 0 0 100% !important;
      max-width: 100% !important;
      min-width: 100% !important;
      width: 100% !important;
      z-index: 999;
      border-right: 0px;

      &.toggle-sidebar {
        left: -100%;
      }

      .workspace-menu {
        .workspace-drop-box {
          position: static;
          width: 100%;
          background: #f9fafe;
          border-radius: 0;
          box-shadow: none;

          .drop-item {
            &:hover {
              background-color: transparent;
            }
          }
        }
      }
    }

    .ant-layout-header {
      padding: 0 1rem 0 1rem;
    }
  }

  .generic-style .ant-layout {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 1024px) {
  .modal-1000 {
    min-width: 800px;
  }
}

@media only screen and (max-width: 991px) {
  .modal-1000 {
    min-width: initial;
  }

  .modal-800 {
    min-width: initial;
  }

  .mb-20 {
    margin-bottom: 10px !important;
  }

  .mb-30 {
    margin-bottom: 20px !important;
  }

  .mb-40 {
    margin-bottom: 20px !important;
  }

  .mb-50 {
    margin-bottom: 25px !important;
  }

  .mb-60 {
    margin-bottom: 30px !important;
  }

  .connection-status-badge {
    margin-left: 5px;
  }

  .hd1 {
    font-size: 20px;
  }

  .generic-style .ant-layout {
    margin-left: 0px;
  }

  .dashboard-content-container {
    .dashboard-content-full {
      .footer-main {
        justify-content: center;

        .footer-nav {
          li {
            padding: 0 5px;

            a {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .default-btn{
    &.medium-btn {
    &.mob-top-btn-set {
      height: 30px;
    }
    }
    }
  .mb-25 {
    margin-bottom: 15px !important;
  }

  .stream-box-white {
    padding: 20px 15px;
  }

  .no-result-found {
    h2 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }

  .modal-header-style2 .modal-title {
    font-size: 16px;
  }

  .modal-header-style2 .close {
    top: 5px;
  }

  .default-btn.medium-btn.fltr-hpr {
    font-size: 12px;
  }

  .events-tabs-main {
    .ant-tabs-tab {
      font-size: 13px;
    }
  }

  .modal-500 {
    min-width: initial;
  }

  .min-modal-600 {
    .ant-modal {
      min-width: initial;
    }
  }

  .modal-600 {
    min-width: initial;
  }

  .modal-700 {
    min-width: initial;
  }

  .ant-image-preview-img-wrapper {
    max-width: 70%;
    max-height: 70%;
  }

  .events-tabs-main {
    .ant-tabs-tab {
      padding: 0.3rem 0.5rem;
      min-width: 40px;
    }
  }

  .mb-50 {
    margin-bottom: 20px !important;
  }

  .mb-60 {
    margin-bottom: 15px !important;
  }

  .generic-style .ant-layout-sider .ant-layout-sider-children {
    padding: 15px;
  }

  .generic-style .ant-layout-sider .ant-menu-inline .ant-menu-item.beta-link,
  .generic-style .ant-layout-sider .ant-menu-vertical .ant-menu-item.beta-link {
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .generic-style .submenu-wrapper {
    bottom: 20px;
    position: static;
  }

  .generic-style .submenu-main .ant-menu-sub.ant-menu-inline {
    padding: 0px 15px;
  }

  .generic-style .sidebar-main .workspace-menu {
    margin-bottom: 10px;
  }

  .generic-style {
    .ant-layout-header {
      .trigger {
        line-height: 47px;
      }

      .logo {
        width: 75px;
      }

      &.site-layout-background {
        height: 45px;
        line-height: 45px;
      }
    }
  }

  .default-btn {
    font-size: 13px;
  }

  .header-main {
    .header-title {
      font-size: 1rem;
      line-height: 47px;
    }

    .logout-btn {
      font-size: 12px;
      height: 27px;
    }
  }

  .min-modal-600 {
    .ant-modal {
      min-width: initial;
    }
  }

  .date-time-main {
    display: block;
  }

  .dashboard-content-container {
    .dashboard-content-full {
      .dashboard-inner-content {
        padding: 1rem;
      }
    }
  }

  .generic-style {
    .sidebar-main {
      // flex: 0 0 80% !important;
      // max-width: 80% !important;
      // min-width: 80% !important;
      // width: 80% !important;
      // overflow-y: scroll;
      overflow-x: hidden;

      .logo-area {
        margin: 0 0 20px 0;
      }

      .event-btn {
        margin: 0 0 10px 0;
        height: 35px !important;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .select-role-list {
    padding: 0;
  }

  .select-role-list .ant-radio-group {
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fill, minmax(33%, 1fr));
  }

  .default-btn {
    &.res-btn1 {
      font-size: 13px;
      min-width: initial;
      height: 30px;
      padding: 5px 10px 0px 10px;
    }

    &.res-btn2 {
      font-size: 13px;
      min-width: initial;
      height: 30px;
      padding: 1px 10px 0px 10px;
    }

    &.res-btn3 {
      font-size: 13px;
      min-width: initial;
      height: 30px;
      padding: 1px 10px 0px 10px;
    }
  }

  .upload-image-main {
    &.ant-upload.ant-upload-drag {
      width: 296px;
      height: 165px;
    }
  }

  .preview-image-main {
    .ant-image {
      width: 296px;
      height: 165px;

      .ant-image-img {
        width: initial;
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .grid-listing-area {
    .select-style {
      width: 150px;
    }
  }

  .grid-listing-area {
    .grid-listing-right {
      .default-btn {
        min-width: initial;
        height: 35px;
        padding: 3.4px 15px;
      }
    }
  }
}

/* Absolute Center Spinner */
.sync_loading {
  position: fixed;
  z-index: 999;
  height: 6em;
  width: 14em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.sync_loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
      rgba(0, 0, 0, 0.8));
  z-index: -1;
}

/* :not(:required) hides these rules from IE9 and below */
.sync_loading:not(:required) {
  /* hide "loading..." text */
  // font: 0/0 a;
  // color: rgb(255 255 255 / 75%);
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading_text {
  // color: #fff;
  // position: relative;
  top: 66px;
  position: relative;
  // left: -39px;
  color: #d6dee5;
  font-size: 26px;
}

.sync_loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  position: relative;
  left: 95px;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

.sync_loading .spinner-grow {
  position: relative;
  width: 0.7rem;
  height: 0.7rem;
  position: relative;
  top: -5px;
  left: 6px;
  margin-right: 4px;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.carouselsvg {
  // width: 22px !important;
  // right: 0px!important;
  // top: -2px !important;
}

.total-sales {
  font-family: Segoe UI;
  font-weight: 800;
}

.deposit_card {
  padding: 0 10px;
  border: 1px solid #ede9e9;
  margin-bottom: 10px;
  height: 44px;
  display: flex;
  align-items: center;
}

.deposit_card input[type="radio"]:checked:after {
  width: 13px;
  height: 13px;
  border-radius: 15px;
  top: -3px;
  left: -1px;
  position: relative;
  background-color: #000080;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #ffffff;
  outline: 1px solid #000080;
}

.deposit_card:hover,
.deposit_card label:hover {
  background-color: #ede9e9;
  cursor: pointer;
}

.deposit_card label {
  margin-left: 10px;
  margin-bottom: 0;
  width: 96%;
}

.card-name {
  text-transform: uppercase;
}

.deposit_loader {
  height: 200px;
}

.deposit-error {
  position: absolute;
}

.custom-paginate .custom-paginate-next:before {
  // content: "\f054";
  content: "";
  font-family: FontAwesome;
}

.custom-paginate .custom-paginate-prev:before {
  // content: "\f053";
  content: "";
  font-family: FontAwesome;
}

.pre-content-upload .status {
  display: none;
}

.account-deleted {
  text-align: center;
  margin-top: 28%;
}

#uploadVideoFile {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block !important;
}


