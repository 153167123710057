.review-video .video-react .video-react-poster {
  // background-size: cover;
}

.review-video .video-react.video-react-fluid {
  height: 100%;
}

.review-video .video-react-button {
  right: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  top: 0
}

.review-video .review_react_player .react-player__preview {
  height: 100%;
  background-position: center center !important;
  background-size: contain !important;
  background-color: black !important;
  background-repeat: no-repeat !important;
}

.review-video .review_react_player video {
  background-color: black !important;
  // object-fit: cover !important;
}

.review_react_player.objectFit video {
  object-fit: cover !important;
}

.modal-review-right .form-styling .ant-form-item {
  margin-bottom: 10px;
}

.upload-image-review {
  &.ant-upload.ant-upload-drag {
    // width: 353px;
    // height: 197px;
    background-color: #ffffff;
    border-radius: 7px;

    p {
      color: #a9a19c;
      transition: color 0.3s;
    }

    &:hover {
      p {
        color: #000080;
      }
    }
  }

  +.ant-upload-list-picture {
    .ant-upload-list-item-list-type-picture {
      // max-width: 353px;

      .anticon-delete {
        color: #ff4d4f;
      }
    }
  }
}

.campaign-header.review-control-box.col-12 {
  align-items: center;


}

.category-text-main {
  // margin-bottom: 1rem;

  .category-text {
    background-color: #586197;
    color: #ffffff;
    padding: 2px 12px 4px 12px;
    border-radius: 100px;
    text-align: center;
    font-size: 12px;
    width: max-content;
  }
}

.cam-buttons.col-12.review-control-btns {
  .btn {
    background-color: #000080;
    border-color: #000080;

    &:hover {
      background-color: #000080;
      border-color: #000080;

    }

    &:first-child {
      border-right: 1px solid white;
    }
  }
}

.review-box-header .ant-avatar.ant-avatar-icon {
  font-size: 14px;
  outline: 1px solid #eee;
}

.modal-video-review-box {
  height: 274px;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload_btn_review {
  position: relative;
  z-index: 0
}

.review-paginate.custom-paginate .custom-paginate-prev:before,
.review-paginate.custom-paginate .custom-paginate-next:before {
  content: none
}

.review-paginate.custom-paginate>.active>a,
.review-paginate.custom-paginate>.active>span,
.review-paginate.custom-paginate>.active>a:hover,
.review-paginate.custom-paginate>.active>span:hover,
.review-paginate.custom-paginate>.active>a:focus,
.review-paginate.custom-paginate>.active>span:focus {
  background-color: #000080 !important;
  border: 1px solid #000080 !important;
  outline: none;
  color: #fff !important;
}

.influencer-reviews-main {
  .analytic-box {
    // padding-top: 0px;

    &.card {
      border-radius: 5px 5px 5px 5px;
    }
  }

  .review-video .video-react.video-react-fluid {
    // border-radius: 5px 5px 0 0;
  }

  .campaign-header.col-12 {
    // margin-bottom: 70px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 5px;

    .review-box-header {
      .anticon {
        position: relative;
        top: -3px;
      }
    }

    .inf-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      gap: 5px;
      margin-bottom: 1rem;
      align-items: center;
    }

    .inf-head-followers {
      display: flex;
      justify-content: center;
      align-items: center;

      .fol-icon {
        color: grey;
        font-size: 25px;
      }

      h5 {
        font-size: 1.25rem;
        color: gray;
        margin: 3px 0 0 5px;
        font-family: "Nunito Sans", sans-serif !important;
        font-weight: 600 !important;
        text-transform: uppercase;
      }
    }

    .btm-title {
      min-height: 50px;
      margin-bottom: 5px;
      margin-top: 10px;
      font-size: 20px;
      font-family: "Nunito Sans", sans-serif !important;

      height: 52px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    }

    .inf-username-box {
      display: flex;
      flex-direction: column;
      gap: 2px;

      .inf-username {
        color: #000080;
        font-size: 14px;
        font-weight: 700;
        // font-family: "Segoe UI" !important;
        font-family: "Nunito Sans", sans-serif !important;
      }
    }

    .inf-follower-box {
      position: relative;
    }

    .inf-email {
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      color: rgba(0, 0, 0, 0.7);
      line-height: 1.43;
      letter-spacing: 0.01071em;
    }
  }

  .any-post-img-col.col-12 {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 15px;
    max-height: 250px;

    .any-post-image:after {
      padding-top: 250px;
    }
  }

  .cam-buttons.col-12 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    bottom: 1.5rem;

    .default-btn {
      font-size: 13px;
    }
  }
}


.inf-content-area {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 70px;

  .event-date-main {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 5px;
    // margin-bottom: 15px;




    .event-date {
      display: flex;
      align-items: center;
      // padding-bottom: 5px;

      .event-icon {
        margin-right: 8px;
        color: #7d84a9;
      }

      .event-txt {
        margin-right: 10px;
        color: grey;
        font-size: 13px;
        // font-family: Nunito Sans, sans-serif !important;

        .ant-statistic-content {
          font-size: 14px;
          color: #eb335b;
        }

        &.recorded-gap {
          margin-right: 2px;
        }
      }


      &.bottom-style {
        padding-bottom: 0;

        .event-icon {
          color: #1a2458;
        }

        .event-txt {
          color: #162b4c;

        }
      }
    }

  }

}

.select-influencer>span+span+div {
  border: 1px solid #000;
  border-radius: 0px;
  min-height: 40px;
  box-shadow: none;

  &:hover {
    border: 1px solid #000;
    border-radius: 0px;
  }

  &:focus {
    border: 1px solid #000;
    border-radius: 0px;
  }
}

.select-inf-plus-main {
  display: flex;
  justify-content: space-between;

  .select-inf-field {
    flex-grow: 1;
  }

  .default-btn {
    border-radius: 0;
  }
}

.add-influencer-form {
  &.w-90 {
    width: 90%;
    margin: 0 auto;
  }

  .ant-input-number-lg {
    border: 1px solid #000;
  }

  .ant-form-item-explain-error {
    margin-bottom: 6px;
  }

  .insta-profile {
    border: 1px solid #000;
    padding: 10px 0;

    // img {
    //   border-radius: 50%;
    // }

    .count {
      text-transform: uppercase;
    }
  }
}


.insta-upload-data {
  border: 1px solid #000;
  padding: 4px 8px;
  margin-bottom: 8px;
}


.Influencer-listing-box {
  display: flex;
  justify-content: space-between;
  border-bottom: solid 1px #ede9e9;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 8px;
}

.Influencer-listing-box:last-child {
  margin-bottom: 0;
  border-bottom: none;
}

.Influencer-listing-box .count {
  font-size: 1rem;
}

.Influencer-listing-box .count-title {
  color: #000000;
  font-size: 1rem;
}

.Influencer-listing-box:last-child .count {
  margin-bottom: 0;
}

.Influencer-listing-box:last-child .count-title {
  margin-bottom: 0;
}

.influencer-modal-data-main.col {
  padding-left: 140px;
}

.influencer-pic {
  width: 110px;
  height: 110px;
  border: 2px solid #798892;
  background: black;
  border-radius: 100%;
  overflow: hidden;
  position: absolute;
  left: 12px;
  display: flex;
  top: 3px;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;

  }
}

.input-group-text.at-styleupload {
  border: 1px solid #000;
  border-right: 0px;
  border-radius: 0.25rem 0rem 0rem 0.25rem;
}

.reviews-view-sale-info {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-bottom: 0;

  .reviews-views-sales-box {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 10px;
    font-size: 14px;

    .reviews-views-sales-hd {
      color: #1a2458;
    }
  }
}

.creator-error {
  color: red;
  font-size: 80%;
  font-weight: 400;
  width: 90%;
  display: table;
  margin: -10px auto 10px auto;
  position: relative;
  padding: 0 0 0 46px;
}
.select-file-error-txt {
  color: red;
  font-size: 80%;
  font-family: "Segoe UI" !important;
}


.tabs-overflow-scroll {
  overflow-x: auto;
  overflow-y: hidden;
}

@media screen and (max-width: 1640px) and (min-width: 1201px) {
  .post-analytics-tab-boxes-ift.influencer-reviews-main {
    .col-xl-3 {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }

  }
}